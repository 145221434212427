import React, { useMemo, useReducer, useState } from "react";
import { Grid, Button, IconButton, Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";
import { FiPlus } from "react-icons/fi";
import SearchField from "./SearchField";
import dayJs from "dayjs";
import { DocumentNode } from "graphql";
import { useQuery } from "@apollo/client";
import useDidMountEffect from "../hooks/useDidMountEffect";
import useDebounce from "../hooks/useDebounce";
import { Delete, Edit } from "@mui/icons-material";
import dayjs from "dayjs";

type SingleSortModel = {
  field: string;
  sort: GridSortDirection;
};

type RemoteDatatableState = {
  search: string;
  sortModel: SingleSortModel;
  limit: number;
  currentPage: number;
};

type Action =
  | { type: "searchChanged"; search: string }
  | { type: "sortModelChanged"; model: SingleSortModel }
  | { type: "currentPageChanged"; page: number }
  | { type: "limitChanged"; limit: number };

export type BasicTableActionFunction<ItemType> = (item: ItemType) => void;
export const getBasicActionColumns = <ItemType,>(
  onEditPress?: BasicTableActionFunction<ItemType>,
  onDeletePress?: BasicTableActionFunction<ItemType>
): GridColDef[] => [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },
  {
    // flex: 1,
    field: "actions",
    align: "center",
    headerName: "",
    minWidth: 100,
    sortable: false,
    filterable: false,
    renderCell: ({ row }) => (
      <>
        <IconButton
          onClick={() => {
            if (onEditPress) onEditPress(row as ItemType);
          }}
        >
          <Edit />
        </IconButton>

        <IconButton
          onClick={() => {
            if (onDeletePress) onDeletePress(row as ItemType);
          }}
        >
          <Delete />
        </IconButton>
      </>
    ),
  },
];

const reducer = (
  state: RemoteDatatableState,
  action: Action
): RemoteDatatableState => {
  switch (action.type) {
    case "searchChanged":
      return {
        ...state,
        search: action.search,
        currentPage: 0,
      };

    case "currentPageChanged":
      return { ...state, currentPage: action.page };

    case "limitChanged":
      return { ...state, limit: action.limit };

    case "sortModelChanged":
      return { ...state, sortModel: action.model };
    default:
      return state;
  }
};

const initialState: RemoteDatatableState = {
  search: "",
  sortModel: { field: "createdAt", sort: "desc" },
  limit: 10,
  currentPage: 0,
};
// interface TableItemBase {
//   id: string;
//   createdAt?: string | Date;
//   updatedAt?: string | Date;
// }

export interface RemoteDataTableProps {
  query: DocumentNode;
  columns: GridColumns;
  actionColumns?: GridColumns;
  variables?: any;
  renderAddButton?: boolean;
  disableAddButton?: boolean;
  renderDateColumns?: boolean;
  searchable?: boolean;
  refresherBoolean?: boolean;
  extraComponent?: React.ReactNode;
  onAddClick?: () => void;
  handlePDF?: (itemsArr: Array<Object>) => void;
  pdf?: boolean;
}

const RemoteDataTable = ({
  query,
  columns,
  variables,
  renderAddButton,
  disableAddButton,
  renderDateColumns = true,
  searchable = true,
  refresherBoolean,
  actionColumns,
  extraComponent,
  onAddClick,
  handlePDF,
  pdf,
}: RemoteDataTableProps) => {
  const [{ search, currentPage, limit, sortModel }, dispatch] = useReducer(
    reducer,
    initialState
  );

  const [ShowToolBar, setShowToolBar] = useState(false);
  const debouncedSearch = useDebounce(search, 500);

  const { data, refetch, loading } = useQuery(query, { variables });

  const { nodes, total } = useMemo(() => {
    if (!data) return { nodes: [] as any[], total: 0 };
    const resultKey = getFirstKey(data);
    if (!resultKey) return { nodes: [] as any[], total: 0 };
    const possibleNodes: any[] = data[resultKey].nodes;
    return { nodes: possibleNodes, total: data[resultKey].totalsCount };
  }, [data]);
  const finalColumns = useMemo(() => {
    let tempColumns = [...columns];
    if (renderDateColumns) {
      tempColumns = [...tempColumns, ...dateColumns];
    }

    if (actionColumns) {
      tempColumns = [...tempColumns, ...actionColumns];
    }
    return tempColumns;
  }, [columns, renderDateColumns, actionColumns]);

  const handleOnSortChange = (model: GridSortModel) => {
    dispatch({
      type: "sortModelChanged",
      model: model.length ? model[0] : { field: "createdAt", sort: "desc" },
    });
  };

  const onLimitChange = (limit: number) => {
    dispatch({
      type: "limitChanged",
      limit,
    });
  };

  const onPageChange = (page: number) => {
    dispatch({
      type: "currentPageChanged",
      page,
    });
  };

  useDidMountEffect(() => {
    refetch({
      ...variables,
      search: debouncedSearch,
      sortBy: sortModel.field,
      sortDirection: sortModel.sort,
      limit: limit,
      offset: limit * currentPage,
    });
  }, [debouncedSearch, sortModel, currentPage, limit, refresherBoolean]);

  return (
    <Grid container justifyContent="flex-end" spacing={2} flex={1}>
      {searchable && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SearchField
            onChange={(e) => {
              dispatch({ search: e.target.value, type: "searchChanged" });
            }}
          />
        </Grid>
      )}
      {renderAddButton && (
        <Grid item xs={12} sm={3} md={3} lg={2} xl={1}>
          <Button
            disableElevation
            disabled={disableAddButton}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onAddClick}
            startIcon={<FiPlus />}
          >
            Add New
          </Button>
        </Grid>
      )}

      <Grid item xs={12} height="80%" maxHeight={"800px"}>
        <DataGrid
          style={{
            border: "none",
          }}
          rowCount={total}
          loading={loading}
          onSortModelChange={handleOnSortChange}
          columns={finalColumns}
          pageSize={limit}
          onPageSizeChange={onLimitChange}
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={onPageChange}
          paginationMode="server"
          sortingMode="server"
          rows={nodes || []}
          checkboxSelection={pdf}
          // autoHeight
        />
      </Grid>
    </Grid>
  );
};

export interface PaginationArgs {
  sortDirection?: "asc" | "desc";

  sortBy?: string;

  limit?: number;

  offset?: number;

  search?: string;
}
const dateColumns: GridColumns = [
  {
    flex: 1,
    field: "createdAt",
    headerName: "Created At",
    minWidth: 200,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
  {
    flex: 1,
    field: "updatedAt",
    headerName: "Updated At",
    minWidth: 200,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
];

const getFirstKey = (object: object) => {
  for (let key in object) {
    return key;
  }
};

export default RemoteDataTable;

import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  FormControlLabelProps,
  styled,
  useRadioGroup,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  GetUserCustomRolesPaginatedDocument,
  GetUsersPaginatedDocument,
  useAssignCustomRoleToUserMutation,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";

import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetAllResellerZonesPaginatedDocument,
  GetAllResellerZonesPaginatedQuery,
  GetResellersPaginatedDocument,
  GetResellerUsersDocument,
  ResellersPaginatedItemFragment,
  ResellerZonesPaginatedDocument,
  ResellerZonesPaginatedItemFragment,
  useAddZoneToResellerMutation,
  useGetResellerByUserIdLazyQuery,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateCustomerZoneDto,
  CreateResellerZoneDto,
  CreateSubResellerZoneDto,
  ResellerZoneDto,
  UsersLinkedDto,
  UsersLinkedPaginatedDto,
} from "../../../apollo/types.generated";
import { DocumentNode, useLazyQuery, useQuery } from "@apollo/client";
import { GetZoneByNameDocument } from "../../../apollo/zones/queries.generated";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  GetSubResellerUsersDocument,
  useAddZoneToSubResellerMutation,
  useGetSubResellerByUserIdLazyQuery,
} from "../../../apollo/subresellers/queries.generated";
import {
  GetCustomerUsersDocument,
  useAddZoneToCustomerMutation,
  useCustomerByUserIdLazyQuery,
} from "../../../apollo/customers/queries.generated";

interface AssignCustomRoleModalProps {
  open: boolean;
  onClose: () => void;
}

interface FormInputs {
  userId: string;
  customRolesId: string;
}

const getSchema = () =>
  Yup.object({
    userId: Yup.string().required(),
    customRolesId: Yup.string().required(),
  }).required();

const AssignCustomRoleModal: React.FC<AssignCustomRoleModalProps> = ({
  open,
  onClose,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema()),
      shouldFocusError: true,
    }
  );
  const [create, { data, loading, error }] = useAssignCustomRoleToUserMutation({
    refetchQueries: [GetUsersPaginatedDocument],
  });

  const [usersQuery, setusersQuery] = React.useState<DocumentNode>(
    GetUsersPaginatedDocument
  );
  const [userRoleId, setUserRoleId] = React.useState<string>("");

  const [getReseller, { data: resellerData }] =
    useGetResellerByUserIdLazyQuery();
  const [getSubReseller, { data: subResellerData }] =
    useGetSubResellerByUserIdLazyQuery();
  const [getCustomer, { data: customerData }] = useCustomerByUserIdLazyQuery();

  const onSubmit = (data: FormInputs) => {
    const { ...rest } = data;
    const dataToSubmit = { ...rest };

    // if (editingSubReseller) {
    //   update({
    //     variables: {
    //       id: editingSubReseller.id,
    //       data: dataToSubmit,
    //     },
    //   });
    //   return;
    // }

    create({
      variables: {
        data: {
          ...dataToSubmit,
        },
      },
    });
  };

  useEffect(() => {
    if (authUser) {
      if (authUser?.role === "SuperAdmin") {
        setusersQuery(GetUsersPaginatedDocument);
      }
      if (authUser?.role === "Reseller") {
        setusersQuery(GetResellerUsersDocument);

        getReseller({
          variables: {
            id: authUser?.id,
          },
        });
      }
      if (authUser?.role === "SubReseller") {
        setusersQuery(GetSubResellerUsersDocument);

        getSubReseller({
          variables: {
            id: authUser?.id,
          },
        });
      }
      if (authUser?.role === "Customer") {
        setusersQuery(GetCustomerUsersDocument);

        getCustomer({
          variables: {
            userId: authUser?.id,
          },
        });
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (resellerData) {
      setUserRoleId(resellerData.getReseller?.id);
    }
  }, [resellerData]);

  useEffect(() => {
    if (subResellerData) {
      setUserRoleId(subResellerData.getSubReseller?.id || "");
    }
  }, [subResellerData]);

  useEffect(() => {
    if (customerData) {
      setUserRoleId(customerData.customerByUserId?.id || "");
    }
  }, [customerData]);

  useEffect(() => {
    if (data) {
      toast.success("Custom role assigned successfully");
      reset();
      onClose();
    }

    if (error) {
      toast.error("Error assigning custom role: " + error.message);
    }
  }, [data, error]);

  return (
    <Dialog
      open={open}
      onClose={!loading ? onClose : undefined}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {/* {editingZone ? "Update Assigned" : "Assign"}  */}
          Assign Custom role to User
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledSelectModalField
                name="userId"
                control={control}
                textFieldProps={{
                  label: "User",
                  fullWidth: true,
                }}
                query={usersQuery}
                variables={
                  authUser?.role === "SuperAdmin"
                    ? {}
                    : { userRoleId: userRoleId }
                }
                labelsExractor={(item) => ({
                  primary:
                    authUser?.role === "SuperAdmin"
                      ? item.name
                      : item.user.name || "",
                  secondary:
                    authUser?.role === "SuperAdmin"
                      ? item.role
                      : item.user.role || "",
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledSelectModalField
                name="customRolesId"
                control={control}
                textFieldProps={{
                  label: "Custom Role",
                  fullWidth: true,
                }}
                query={GetUserCustomRolesPaginatedDocument}
                variables={
                  authUser?.role === "SuperAdmin"
                    ? {}
                    : { userId: authUser?.id }
                }
                labelsExractor={(item) => ({
                  primary: item.name || "",
                  secondary: item.desc || "",
                })}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}></Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={loading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AssignCustomRoleModal;

import React, { cloneElement } from "react";
import {
  Close,
  LocationCityRounded,
  SchoolOutlined,
  FamilyRestroom,
  CalendarToday,
} from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Divider,
  Drawer as MuiDrawer,
  IconButton,
  Theme,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FiCamera, FiHexagon, FiHome, FiUsers } from "react-icons/fi";
import { FaRegCalendarCheck } from "react-icons/fa";
import { shouldForwardProp, styled } from "@mui/system";
import { useRouteMatch } from "react-router";
import { NavLink } from "react-router-dom";
import { FaBusAlt, FaRoad, FaUserAlt, FaUsers } from "react-icons/fa";
import ReceiptIcon from "@mui/icons-material/Receipt";
import useAbility from "../shared/hooks/useAbility";
import DomainIcon from "@mui/icons-material/Domain";
interface DrawerProps {
  isOpen: boolean;
  onCloseClick?: () => void;
}

export const drawerWidth = 260;

const Drawer: React.FC<DrawerProps> = ({ isOpen, onCloseClick }) => {
  const ability = useAbility();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const drawerContent = (
    <>
      <Toolbar
        sx={{
          height: "100px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            noWrap
            component="div"
            fontWeight="bold"
            color="primary"
            // sx={{ display: { xs: "block", sm: "block" } }}
          >
            Cloudflare
          </Typography>
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            sx={{
              mr: 2,
              display: {
                lg: "none",
              },
            }}
            onClick={onCloseClick}
          >
            <Close />
          </IconButton>
        </Box>
      </Toolbar>
      <Box
        sx={{
          marginBottom: "24px",
          paddingX: {
            xs: "24px",
          },
        }}
      >
        <DrawerItem label="Zones" icon={<FiHome />} path="/dashboard" exact />

        {ability.can("manage", "Zones") && (
          <DrawerItem
            label="Manage Zones"
            icon={<DomainIcon />}
            path="/dashboard/manageZones"
          />
        )}

        {ability.can("read", "Resellers") && (
          <DrawerItem
            label="Resellers"
            icon={<FaUserAlt />}
            path="/dashboard/resellers"
          />
        )}

        {ability.can("read", "Sub Resellers") && (
          <DrawerItem
            label="Sub Resellers"
            icon={<FaUserAlt />}
            path="/dashboard/subresellers"
          />
        )}

        {ability.can("read", "Customers") && (
          <DrawerItem
            label="Customers"
            icon={<FaUserAlt />}
            path="/dashboard/customers"
          />
        )}
        {ability.can("read", "Roles") && (
          <DrawerItem
            label="Custom Roles"
            icon={<FaUserAlt />}
            path="/dashboard/roles"
          />
        )}

        {ability.can("read", "Permissions") && (
          <DrawerItem
            label="Permissions"
            icon={<FaUserAlt />}
            path="/dashboard/permissions"
          />
        )}

        {ability.can("read", "Users") && (
          <DrawerItem
            label="Users"
            icon={<FaUserAlt />}
            path="/dashboard/users"
          />
        )}

        {/* <DrawerItem label="Home" icon={<FiCamera />} /> */}
      </Box>
      {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader> */}
      <Divider />
    </>
  );

  return (
    <>
      <MuiDrawer
        variant={isLg ? "permanent" : isMd ? "persistent" : "temporary"}
        open={isOpen}
        anchor="left"
        onClose={onCloseClick}
        sx={{
          // display: {
          //   xs: "block",
          //   md: "block",
          // },
          width: drawerWidth,
          flexShrink: 0,
          overflowX: "hidden",
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            overflowX: "hidden",
            boxSizing: "border-box",
          },
        }}
      >
        {drawerContent}
      </MuiDrawer>
    </>
  );
};

interface DrawerItemBoxProps {
  selected?: boolean;
}

const DrawerItemBox = styled(ButtonBase)<DrawerItemBoxProps>(
  ({ theme, selected }) => {
    const typedTheme = theme as Theme;
    return {
      padding: theme.spacing("10px", "15px"),
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      display: "flex",
      backgroundColor: selected
        ? typedTheme.palette.primary.main
        : "transparent",
      color: selected ? "white" : typedTheme.palette.grey[600],
      alignItems: "center",
      justifyContent: "flex-start",
    };
  }
);

interface DrawerItemProps {
  path: string;
  icon: React.ReactNode;
  label: string;
  exact?: boolean;
}
const DrawerItem: React.FC<DrawerItemProps> = ({
  icon,
  label,
  path,
  exact,
}) => {
  const isActive = useRouteMatch({
    path,
    exact,
  });
  const moddedIcon = cloneElement(icon as unknown as any, { size: "20px" });

  return (
    <NavLink to={path} style={{ textDecoration: "none" }}>
      <DrawerItemBox selected={!!isActive}>
        <Box
          marginRight="15px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          {moddedIcon}
        </Box>
        <Typography>{label}</Typography>
      </DrawerItemBox>
    </NavLink>
  );
};
export default Drawer;

import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";
import {
  GetUsersPaginatedDocument,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";

interface AdminUsersTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<UsersPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<UsersPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const user = row as UsersPaginatedItemFragment;
      return <Link to={"/dashboard/user/" + user.id}>{`${user.name}`}</Link>;
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersPaginatedItemFragment;
      return user.email;
    },
  },
  {
    flex: 1,
    field: "role",
    headerName: "Main Role",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersPaginatedItemFragment;
      return user.role;
    },
  },
  {
    flex: 1,
    field: "customRole",
    headerName: "Custom Role",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersPaginatedItemFragment;
      return user.customRole?.customRoles?.name || "None";
    },
  },
  {
    flex: 1,
    field: "permissions",
    headerName: "Total Permissions",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersPaginatedItemFragment;
      return user.permissions?.length || 0;
    },
  },
];

const AdminUsersTable: React.FC<AdminUsersTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetUsersPaginatedDocument}
      searchable
      // renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default AdminUsersTable;

import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
  CustomRoleFragment,
  useCreateCustomRoleMutation,
  GetUserCustomRolesPaginatedDocument,
  UsersPaginatedItemFragment,
  GetUsersPaginatedDocument,
  PermissionsFragment,
  useCreateCustomRolePermissionMutation,
  GetPermissionsCustomRolesPaginatedDocument,
  useUpdatePermissionUserMutation,
  GetPermissionsUsersPaginatedDocument,
  useUpdatePermissionCustomRoleMutation,
} from "../../../apollo/users/queries.generated";

import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
  useCreateResellerMutation,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateCustomRolesDto,
  CreatePermissionsDto,
  CreateResellerDto,
  CreateSubResellerDto,
} from "../../../apollo/types.generated";
import {
  GetSubResellersPaginatedDocument,
  SubResellersPaginatedItemFragment,
  useCreateSubResellerMutation,
} from "../../../apollo/subresellers/queries.generated";

interface CustomRolesPermissionFormModalProps {
  open: boolean;
  onClose: () => void;
  editingPermission?: PermissionsFragment;
}
interface FormInputs extends CreatePermissionsDto {}

const getSchema = (editingPermission?: PermissionsFragment) =>
  Yup.object({
    action: Yup.string().required("This field is required"),
    model: Yup.string().required("This field is required"),
    customRolesId: Yup.string().required("This field is required"),
  }).required();

const auxOptionsAction = ["Read", "Manage"];
const auxOptionsModel = [
  "Zones",
  "DNS",
  "DNS_Record_A",
  "DNS_Record_AAAA",
  "DNS_Record_CNAME",
  "DNS_Record_MX",
  "DNS_Record_NS",
  "DNS_Record_PTR",
  "DNS_Record_CERT",
  "DNS_Record_SRV",
  "DNS_Record_TXT",
  "DNS_Record_SOA",
];

const getDefaultValues = (
  customRole?: PermissionsFragment
): FormInputs | undefined => {
  return {
    action: customRole?.action || "",
    model: customRole?.model || "",
    customRolesId: customRole?.customRoles?.id || "",
  };
};
const CustomRolesPermissionFormModal: React.FC<
  CustomRolesPermissionFormModalProps
> = ({ open, onClose, editingPermission }) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema(editingPermission)),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingPermission),
    }
  );

  const actionAux = watch("action");

  const [optionsAction, setOptionsAction] = React.useState<string[]>();
  const [actionValue, setActionValue] = React.useState<string | null>(
    optionsAction ? optionsAction[0] : null
  );
  const [inputActionValue, setInputActionValue] = React.useState("");

  const [optionsModel, setOptionsModel] = React.useState<string[]>();
  const [modelValue, setModelValue] = React.useState<string | null>(
    optionsModel ? optionsModel[0] : null
  );
  const [inputModelValue, setInputModelValue] = React.useState("");

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateCustomRolePermissionMutation({
    refetchQueries: [GetPermissionsCustomRolesPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdatePermissionCustomRoleMutation({
    refetchQueries: [
      {
        query: GetPermissionsUsersPaginatedDocument,
        variables:
          authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id },
      },
    ],
  });

  const onSubmit = (data: FormInputs) => {
    const { assignedByUserId, userId, ...rest } = data;
    const dataToSubmit = { ...rest };
    if (editingPermission) {
      const auxData = {
        id: editingPermission.id,
        ...dataToSubmit,
      };
      update({
        variables: {
          data: {
            ...auxData,
          },
        },
      });
      return;
    }
    create({
      variables: {
        data: {
          ...dataToSubmit,
        },
      },
    });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Permission created successfully");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error creating Permission: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Role updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Role: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingPermission));

    if (editingPermission) {
      setActionValue(editingPermission.action);
      setModelValue(editingPermission.model);
    }
  }, [editingPermission]);

  useEffect(() => {
    if (!open) {
      setActionValue(null);
      setModelValue(null);
      reset();
    }
  }, [open]);

  useEffect(() => {
    if (authUser?.role === "SuperAdmin") {
      setOptionsAction(auxOptionsAction);
      return;
    }

    if (authUser?.permissions?.length) {
      const actions = authUser?.permissions?.map(
        (permission) => permission.action
      );

      const uniqueActions = actions.filter((v, i, a) => a.indexOf(v) === i);
      setOptionsAction(uniqueActions);
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser?.role === "SuperAdmin") {
      return setOptionsModel(auxOptionsModel);
    }

    if (actionAux === "Read") {
      const models = authUser?.permissions
        ?.filter((permission) => permission.action === "Read")
        .map((permission) => permission.model);

      const uniqueModels = models!.filter((v, i, a) => a.indexOf(v) === i);
      return setOptionsModel(uniqueModels);
    }

    if (actionAux === "Manage") {
      const models = authUser?.permissions
        ?.filter((permission) => permission.action === "Manage")
        .map((permission) => permission.model);

      const uniqueModels = models!.filter((v, i, a) => a.indexOf(v) === i);
      return setOptionsModel(uniqueModels);
    }

    setOptionsModel([]);
  }, [actionAux]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="xs"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingPermission ? "Update" : "Create"} Custom Role Permission
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={actionValue}
                onChange={(event: any, newValue: string | null) => {
                  setActionValue(newValue);
                  setModelValue(null);
                }}
                inputValue={inputActionValue}
                onInputChange={(event, newInputValue) => {
                  setInputActionValue(newInputValue);
                  setValue("action", newInputValue);
                }}
                id="controllable-states-demo"
                options={optionsAction || []}
                renderInput={(params) => (
                  <TextField {...params} label="Action" />
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={modelValue}
                onChange={(event: any, newValue: string | null) => {
                  setModelValue(newValue);
                }}
                inputValue={inputModelValue}
                onInputChange={(event, newInputValue) => {
                  setInputModelValue(newInputValue);
                  setValue("model", newInputValue);
                }}
                id="controllable-states-demo"
                options={optionsModel || []}
                renderInput={(params) => (
                  <TextField {...params} label="Model" />
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledSelectModalField
                name="customRolesId"
                control={control}
                initialValue={
                  editingPermission?.customRoles as CustomRoleFragment
                }
                textFieldProps={{
                  label: "Custom Role",
                  fullWidth: true,
                }}
                query={GetUserCustomRolesPaginatedDocument}
                variables={
                  authUser?.role === "SuperAdmin"
                    ? {}
                    : { userId: authUser?.id }
                }
                labelsExractor={(item) => ({
                  primary: item.name,
                  secondary: item.user?.name,
                })}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomRolesPermissionFormModal;

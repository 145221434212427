import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import RemoteDataTableApi, {
  RemoteDataTableApiProps,
} from "../../../shared/components/RemoteDataTableApi";
import dayjs from "dayjs";
import {
  GetZoneListPaginatedDocument,
  useGetZoneByNameLazyQuery,
  useGetZoneByNameQuery,
} from "../../../apollo/zones/queries.generated";
import {
  DnsRecordFragment,
  GetZoneDnsRecordsDocument,
  useGetDnsRecordLazyQuery,
  useGetZoneDnsRecordsQuery,
} from "../../../apollo/dns/queries.generated";
import { Alert, Button, Grid } from "@mui/material";
import AddDNSModal from "./AddDNSModal";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import { PermissionsDto } from "../../../apollo/types.generated";
import {
  useGetUserLazyQuery,
  useGetUserQuery,
} from "../../../apollo/users/queries.generated";
import LoadingPage from "../../home/LoadingPage";

interface DNSTableProps
  extends Pick<RemoteDataTableApiProps, "onAddClick" | "refresherBoolean"> {
  zoneName: string;
  recordId?: string;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "DNS name",
    minWidth: 300,
    sortable: false,
  },
  {
    flex: 1,
    field: "type",
    headerName: "Type",
    minWidth: 40,
    sortable: false,
  },
  {
    flex: 1,
    field: "content",
    headerName: "Content",
    minWidth: 200,
    sortable: false,
  },
  {
    flex: 1,
    field: "ttl",
    headerName: "TTL",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "proxied",
    headerName: "Proxied",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "priority",
    headerName: "Priority",
    minWidth: 50,
    sortable: false,
    renderCell: ({ value }) => {
      return value ? value : "-";
    },
  },
  {
    flex: 1,
    field: "created_on",
    headerName: "Created At",
    minWidth: 100,
    sortable: false,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
  {
    flex: 1,
    field: "modified_on",
    headerName: "Updated At",
    minWidth: 100,
    sortable: false,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
];

const DNSTable: React.FC<DNSTableProps> = (
  { zoneName, recordId }: DNSTableProps,
  { ...props }
) => {
  const authUser = useAuthUser();
  const [showFormModal, setShowFormModal] = useState(false);
  const [editingDNS, setEditingDNS] = React.useState<DnsRecordFragment>();
  const handleFormClose = () => {
    setEditingDNS(undefined);
    setShowFormModal(false);
  };

  const loadZone = useGetZoneByNameQuery({
    variables: {
      zoneName: zoneName,
    },
  });

  const auxLoader = useGetZoneDnsRecordsQuery({
    variables: { limit: 10, page: 1, zoneName: zoneName },
  });

  const [loadRecord, { data, loading, error }] = useGetDnsRecordLazyQuery();

  if (recordId && !editingDNS) {
    loadRecord({
      variables: {
        zoneName: zoneName,
        recordId: recordId,
      },
    });

    if (data) {
      setEditingDNS(data.getDnsRecord);
    }
  }

  const auxData = [
    { value: "DNS_Record_A", label: "A" },
    { value: "DNS_Record_AAAA", label: "AAAA" },
    { value: "DNS_Record_CNAME", label: "CNAME" },
    { value: "DNS_Record_MX", label: "MX" },
    { value: "DNS_Record_NS", label: "NS" },
    { value: "DNS_Record_PTR", label: "PTR" },
    { value: "DNS_Record_CERT", label: "CERT" },
    { value: "DNS_Record_SRV", label: "SRV" },
    { value: "DNS_Record_TXT", label: "TXT" },
    { value: "DNS_Record_SOA", label: "SOA" },
  ];

  let permmision = authUser?.permissions as any;

  if (permmision) {
    permmision = permmision.filter(
      (permission: PermissionsDto) => permission.action === "Manage"
    );

    permmision = permmision.map((permission: PermissionsDto) => {
      const permissionLabel = auxData.find(
        (aux) => aux.value === permission.model
      )?.label;
      return permissionLabel;
    });
  }

  muiColumns[0].renderCell = ({ row, value }) => {
    const record = row as DnsRecordFragment;

    if (authUser?.role === "SuperAdmin") {
      return (
        <Button
          onClick={() => {
            setEditingDNS(record);
            setShowFormModal(true);
          }}
          size="small"
        >
          {value}
        </Button>
      );
    }

    if (permmision?.includes(record.type)) {
      return (
        <Button
          onClick={() => {
            setEditingDNS(record);
            setShowFormModal(true);
          }}
          size="small"
        >
          {value}
        </Button>
      );
    } else {
      return <>{value}</>;
    }
  };

  return (
    <Grid container>
      {error && (
        <Grid item xs={12}>
          <Alert severity="error">{error.message}</Alert>
        </Grid>
      )}

      {loadZone.loading && <LoadingPage />}

      {!auxLoader.loading &&
      auxLoader.data?.getZoneDnsRecords?.nodes !== null &&
      auxLoader.data?.getZoneDnsRecords?.nodes !== undefined &&
      auxLoader.data?.getZoneDnsRecords?.nodes.length > 0 ? (
        <Grid item xs={12}>
          <AddDNSModal
            open={showFormModal}
            onClose={handleFormClose}
            zoneName={zoneName}
            zoneId={loadZone.data?.getZoneByName?.id || ""}
            editingDNS={editingDNS}
          />
          <RemoteDataTableApi
            query={GetZoneDnsRecordsDocument}
            variables={{ limit: 10, page: 1, zoneName: zoneName }}
            columns={muiColumns}
            renderAddButton={true}
            onAddClick={() => setShowFormModal(true)}
            {...props}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Alert severity="error">
            You don't have any DNS records in this zone or you don't have
            permission to view them.
          </Alert>
          <br />
          <Alert severity="info">
            Contact your administrator if you think this is an error.
          </Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default DNSTable;

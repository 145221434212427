import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";
import {
  GetPermissionsUsersPaginatedDocument,
  PermissionsFragment,
} from "../../../apollo/users/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";

interface UserPermissionTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<PermissionsFragment>;
  onDeletePress?: BasicTableActionFunction<PermissionsFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "actionModel",
    headerName: "Permission",
    minWidth: 250,
    valueGetter: ({ row }) => {
      const permission = row as PermissionsFragment;
      return `${permission.action} ${permission.model}`;
    },
  },
  {
    flex: 1,
    field: "givenTo",
    headerName: "Given to",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const permission = row as PermissionsFragment;
      return permission.user?.name;
    },
  },
];

const UserPermissionTable: React.FC<UserPermissionTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  const authUser = useAuthUser();

  if (authUser?.role === "SuperAdmin") {
    const fieldExists = muiColumns.find(
      (column) => column.field === "permissionAssignedBy"
    );
    if (!fieldExists)
      muiColumns.push({
        flex: 1,
        field: "permissionAssignedBy",
        headerName: "Assigned by",
        minWidth: 200,
        valueGetter: ({ row }) => {
          const permission = row as PermissionsFragment;
          return permission.permissionAssignedBy?.name;
        },
      });
  }

  return (
    <RemoteDataTable
      query={GetPermissionsUsersPaginatedDocument}
      variables={
        authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id }
      }
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default UserPermissionTable;

import { Typography } from "@mui/material";
import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  DeletePermissionsCustomRoleDocument,
  GetPermissionsCustomRolesPaginatedDocument,
  PermissionsFragment,
} from "../../../apollo/users/queries.generated";
import DeleteModal from "../../../shared/components/DeleteModal";
import PageContainer from "../../../shared/components/PageContainer";
import TableCard from "../../../shared/components/TableCard";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import CustomRolesPermissionFormModal from "./CustomRolesPermissionsFormModal";
import CustomRolesPermissionTable from "./CustomRolesPermissionTable";

interface CustomRolesPermissionPageProps {}

const CustomRolesPermissionPage: React.FC<
  CustomRolesPermissionPageProps
> = ({}) => {
  const authUser = useAuthUser();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPermission, setSelectedPermission] =
    useState<PermissionsFragment>();

  const handleFormClose = () => {
    setSelectedPermission(undefined);
    setShowFormModal(false);
  };

  const handleOpenFormModal = () => {
    if (
      authUser?.permissions?.length === 0 &&
      authUser?.role !== "SuperAdmin"
    ) {
      return toast.error("You don't have zone permissions in your account");
    }
    setShowFormModal(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedPermission(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <CustomRolesPermissionFormModal
        editingPermission={selectedPermission}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeletePermissionsCustomRoleDocument}
        updateQueries={[GetPermissionsCustomRolesPaginatedDocument]}
        variables={{ id: selectedPermission?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Reseller"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Custom Roles Permission
        </Typography>
        <CustomRolesPermissionTable
          onAddClick={() => handleOpenFormModal()}
          onEditPress={(item) => {
            setSelectedPermission(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedPermission(item);
            handleOpenFormModal();
          }}
        />
      </TableCard>
    </PageContainer>
  );
};

export default CustomRolesPermissionPage;

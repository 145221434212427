import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
  CustomRoleFragment,
  useCreateCustomRoleMutation,
  GetUserCustomRolesPaginatedDocument,
  UsersPaginatedItemFragment,
  GetUsersPaginatedDocument,
  useUpdateCustomRoleMutation,
} from "../../../apollo/users/queries.generated";

import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
  useCreateResellerMutation,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateCustomRolesDto,
  CreateResellerDto,
  CreateSubResellerDto,
} from "../../../apollo/types.generated";
import {
  GetSubResellersPaginatedDocument,
  SubResellersPaginatedItemFragment,
  useCreateSubResellerMutation,
} from "../../../apollo/subresellers/queries.generated";

interface RolesFormModalProps {
  open: boolean;
  onClose: () => void;
  editingRole?: CustomRoleFragment;
}
interface FormInputs extends CreateCustomRolesDto {}

const getSchema = (editingRole?: CustomRoleFragment) =>
  Yup.object({
    name: Yup.string().required("This field is required"),
    desc: Yup.string().required("This field is required"),
    userId: Yup.string().optional(),
  }).required();

const getDefaultValues = (
  customRole?: CustomRoleFragment
): FormInputs | undefined => {
  return {
    name: customRole?.name || "",
    desc: customRole?.desc || "",
    userId: customRole?.user?.id || "",
  };
};
const RolesFormModal: React.FC<RolesFormModalProps> = ({
  open,
  onClose,
  editingRole,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema(editingRole)),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingRole),
    }
  );

  const userId = watch("userId");
  const [checked, setChecked] = React.useState(false);
  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateCustomRoleMutation({
    refetchQueries: [GetUserCustomRolesPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateCustomRoleMutation({
    refetchQueries: [GetUserCustomRolesPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    if (checked === false && !data.userId) {
      return toast.error("Please add the creator of the role");
    }
    const { ...rest } = data;
    const dataToSubmit = { ...rest };
    if (editingRole) {
      const auxData = { ...dataToSubmit, id: editingRole.id };

      update({
        variables: {
          data: auxData,
        },
      });
      return;
    }
    create({
      variables: {
        data: {
          ...dataToSubmit,
        },
      },
    });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Role created successfully");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error creating Role: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Role updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Role: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingRole));
  }, [editingRole]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (authUser && checked === false) {
      setValue("userId", authUser?.id);
    } else {
      setValue("userId", "");
    }
  };

  useEffect(() => {
    if (authUser?.role !== "SuperAdmin") {
      setValue("userId", authUser?.id || "");
    }
  }, [authUser]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingRole ? "Update" : "Create"} Custom Role
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField name="name" control={control} label="Name" />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="desc"
                control={control}
                label="Description"
              />
            </Grid>

            {authUser?.role === "SuperAdmin" && (
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="As Super Admin, I want to create this Custom Role for myself"
                  />
                </FormGroup>
                {!checked && (
                  <ControlledSelectModalField
                    name="userId"
                    control={control}
                    // variables={
                    //   authUser?.role === "reseller"
                    //     ? { schoolSystemId: authUser.schoolSystemId }
                    //     : undefined
                    // }
                    initialValue={
                      editingRole?.user as UsersPaginatedItemFragment
                    }
                    textFieldProps={{
                      label: "Created By",
                      fullWidth: true,
                    }}
                    query={GetUsersPaginatedDocument}
                    labelsExractor={(item) => ({
                      primary: item.name,
                      secondary: item.role,
                    })}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default RolesFormModal;

import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import {
  GetPermissionsCustomRolesPaginatedDocument,
  PermissionsFragment,
} from "../../../apollo/users/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";

interface CustomRolesPermissionTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<PermissionsFragment>;
  onDeletePress?: BasicTableActionFunction<PermissionsFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "actionModel",
    headerName: "Permission",
    minWidth: 240,
    valueGetter: ({ row }) => {
      const permission = row as PermissionsFragment;
      return `${permission.action} ${permission.model}`;
    },
  },
  {
    flex: 1,
    field: "customRole",
    headerName: "Assigned Role",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const permission = row as PermissionsFragment;
      return permission.customRoles?.name;
    },
  },
];

const CustomRolesPermissionTable: React.FC<CustomRolesPermissionTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  const authUser = useAuthUser();

  return (
    <RemoteDataTable
      query={GetPermissionsCustomRolesPaginatedDocument}
      variables={
        authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id }
      }
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default CustomRolesPermissionTable;

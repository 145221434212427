import React, { useEffect } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
// import { useAuthUserQuery } from "../apollo/auth/queries";
// import useAbility from "../shared/hooks/useAbility";

import LoadingPage from "../pages/home/LoadingPage";
import useAuthStore, { Action, Subjects } from "../shared/stores/authStore";

interface PrivateRouteProps extends RouteProps {
  can?: [Action, Subjects];
  isCustomer?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  can,
  isCustomer,
  ...rest
}) => {
  const { user, isLoading, authError, authToken } = useAuthStore(
    ({ user, isLoading, authError, authToken }) => ({
      user,
      isLoading,
      authError,
      authToken,
    })
  );

  // const { loading, error, user } = useAuthUserQuery();
  // useEffect(() => {
  //   if (error) {
  //     removeToken();
  //   }
  // }, [error]);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (authError || !authToken) {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: { from: location },
              }}
            />
          );
        }

        // //If this is not a customer route but the user is a customer and tries to access it we should redirect them to a customer route
        // if (user && user.isCustomer && !isCustomer) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/myProperty",
        //         state: { from: location },
        //       }}
        //     />
        //   );
        // }
        // //If this is a customer route but the user is not a customer and tries to access it we should redirect them to the dashboard
        // if (user && !user.isCustomer && isCustomer) {
        //   return (
        //     <Redirect
        //       to={{
        //         pathname: "/dashboard",
        //         state: { from: location },
        //       }}
        //     />
        //   );
        // }
        if (user) return <>{children}</>;

        return <LoadingPage />;
      }}
    />
  );
};

export default PrivateRoute;

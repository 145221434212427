import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
  CustomRoleFragment,
  useCreateCustomRoleMutation,
  GetUserCustomRolesPaginatedDocument,
  UsersPaginatedItemFragment,
  GetUsersPaginatedDocument,
  PermissionsFragment,
  useCreateCustomRolePermissionMutation,
  GetPermissionsCustomRolesPaginatedDocument,
  useCreateUserPermissionMutation,
  GetPermissionsUsersPaginatedDocument,
  useUpdatePermissionUserMutation,
  useGetPermissionsUsersPaginatedLazyQuery,
} from "../../../apollo/users/queries.generated";

import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetResellersPaginatedDocument,
  GetResellerUsersDocument,
  ResellersPaginatedItemFragment,
  useCreateResellerMutation,
  useGetResellerByUserIdLazyQuery,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateCustomRolesDto,
  CreatePermissionsDto,
  CreateResellerDto,
  CreateSubResellerDto,
} from "../../../apollo/types.generated";
import {
  GetSubResellersPaginatedDocument,
  GetSubResellerUsersDocument,
  SubResellersPaginatedItemFragment,
  useCreateSubResellerMutation,
  useGetSubResellerByUserIdLazyQuery,
} from "../../../apollo/subresellers/queries.generated";
import { DocumentNode } from "graphql";
import {
  GetCustomerUsersDocument,
  useCustomerByUserIdLazyQuery,
} from "../../../apollo/customers/queries.generated";

interface UserPermissionFormModalProps {
  open: boolean;
  onClose: () => void;
  editingPermission?: PermissionsFragment;
}
interface FormInputs extends CreatePermissionsDto {}

const getSchema = (editingPermission?: PermissionsFragment) =>
  Yup.object({
    action: Yup.string().required("This field is required"),
    model: Yup.string().required("This field is required"),
    userId: Yup.string().required("This field is required"),
    assignedByUserId: Yup.string()
      .optional()
      .test(
        "assignedByUserId",
        "You can't assign the same user as the Assigned By",
        (value, context) => {
          return value !== context.parent.userId;
        }
      ),
  }).required();

const auxOptionsAction = ["Read", "Manage"];
const auxOptionsModel = [
  "Zones",
  "DNS",
  "DNS_Record_A",
  "DNS_Record_AAAA",
  "DNS_Record_CNAME",
  "DNS_Record_MX",
  "DNS_Record_NS",
  "DNS_Record_PTR",
  "DNS_Record_CERT",
  "DNS_Record_SRV",
  "DNS_Record_TXT",
  "DNS_Record_SOA",
];

const getDefaultValues = (
  userPermission?: PermissionsFragment
): FormInputs | undefined => {
  return {
    action: userPermission?.action || "",
    model: userPermission?.model || "",
    userId: userPermission?.user?.id || "",
    assignedByUserId: userPermission?.permissionAssignedBy?.id || "",
  };
};
const UserPermissionFormModal: React.FC<UserPermissionFormModalProps> = ({
  open,
  onClose,
  editingPermission,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema(editingPermission)),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingPermission),
    }
  );

  const actionAux = watch("action");

  const [checked, setChecked] = React.useState(false);

  const [usersQuery, setusersQuery] = React.useState<DocumentNode>(
    GetUsersPaginatedDocument
  );
  const [userRoleId, setUserRoleId] = React.useState<string>("");
  const [getReseller, { data: resellerData }] =
    useGetResellerByUserIdLazyQuery();
  const [getSubReseller, { data: subResellerData }] =
    useGetSubResellerByUserIdLazyQuery();
  const [getCustomer, { data: customerData }] = useCustomerByUserIdLazyQuery();

  const [optionsAction, setOptionsAction] = React.useState<string[]>();
  const [actionValue, setActionValue] = React.useState<string | null>(
    optionsAction ? optionsAction[0] : null
  );
  const [inputActionValue, setInputActionValue] = React.useState("");

  const [optionsModel, setOptionsModel] = React.useState<string[]>();
  const [modelValue, setModelValue] = React.useState<string | null>(
    optionsModel ? optionsModel[0] : null
  );
  const [inputModelValue, setInputModelValue] = React.useState("");

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateUserPermissionMutation({
    refetchQueries: [
      {
        query: GetPermissionsUsersPaginatedDocument,
        variables:
          authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id },
      },
    ],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdatePermissionUserMutation({
    refetchQueries: [
      {
        query: GetPermissionsUsersPaginatedDocument,
        variables:
          authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id },
      },
    ],
  });

  const onSubmit = (data: FormInputs) => {
    data.assignedByUserId =
      authUser?.role === "SuperAdmin" ? data.assignedByUserId : authUser?.id;
    if (checked === false && !data.assignedByUserId) {
      return toast.error("Please add the user that assigned the permission");
    }

    const compatiblePerms = authUser!.permissions!.filter(
      (permission) =>
        permission.action === data.action && permission.model === data.model
    );

    if (compatiblePerms.length === 0 && authUser?.role !== "SuperAdmin") {
      return toast.error("You can't assign a permission that you don't have");
    }

    const { assignedByUserId, ...rest } = data;
    const dataToSubmit = { ...rest };
    if (editingPermission) {
      const { action, model } = dataToSubmit;
      const auxData = {
        action,
        model,
        assignedByUserId,
        id: editingPermission.id,
      };
      update({
        variables: {
          data: {
            ...auxData,
          },
        },
      });
      return;
    }
    create({
      variables: {
        data: {
          ...dataToSubmit,
          assignedByUserId,
        },
      },
    });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Permission created successfully");
      reset();
      setChecked(false);
      onClose();
    }

    if (createError) {
      toast.error("Error creating Permission: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Permission updated successfully");
      reset();
      setChecked(false);
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Permission: " + updateError.message);
    }
  }, [updateData, updateError]);

  useEffect(() => {
    if (!open) {
      reset();
      setActionValue(null);
      setModelValue(null);
      setChecked(false);
    }
  }, [open]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingPermission));

    if (editingPermission) {
      setActionValue(editingPermission.action);
      setModelValue(editingPermission.model);

      if (editingPermission.permissionAssignedBy?.id === authUser?.id) {
        setChecked(true);
      }
    }
  }, [editingPermission]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    if (authUser && checked === false) {
      setValue("assignedByUserId", authUser?.id);
    } else {
      setValue("assignedByUserId", "");
    }
  };

  useEffect(() => {
    if (authUser?.role === "SuperAdmin") {
      setOptionsAction(auxOptionsAction);
      return;
    }

    if (authUser?.permissions?.length) {
      const actions = authUser?.permissions?.map(
        (permission) => permission.action
      );

      const uniqueActions = actions.filter((v, i, a) => a.indexOf(v) === i);
      setOptionsAction(uniqueActions);
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser?.role === "SuperAdmin") {
      return setOptionsModel(auxOptionsModel);
    }

    if (actionAux === "Read") {
      const models = authUser?.permissions
        ?.filter((permission) => permission.action === "Read")
        .map((permission) => permission.model);

      const uniqueModels = models!.filter((v, i, a) => a.indexOf(v) === i);
      return setOptionsModel(uniqueModels);
    }

    if (actionAux === "Manage") {
      const models = authUser?.permissions
        ?.filter((permission) => permission.action === "Manage")
        .map((permission) => permission.model);

      const uniqueModels = models!.filter((v, i, a) => a.indexOf(v) === i);
      return setOptionsModel(uniqueModels);
    }

    setOptionsModel([]);
  }, [actionAux]);

  useEffect(() => {
    if (authUser && !editingPermission) {
      if (authUser?.role === "SuperAdmin") {
        return setusersQuery(GetUsersPaginatedDocument);
      }
      if (authUser?.role === "Reseller") {
        setusersQuery(GetResellerUsersDocument);

        getReseller({
          variables: {
            id: authUser?.id,
          },
        });
        return;
      }
      if (authUser?.role === "SubReseller") {
        setusersQuery(GetSubResellerUsersDocument);

        getSubReseller({
          variables: {
            id: authUser?.id,
          },
        });
        return;
      }
      if (authUser?.role === "Customer") {
        setusersQuery(GetCustomerUsersDocument);

        getCustomer({
          variables: {
            userId: authUser?.id,
          },
        });
        return;
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (resellerData) {
      setUserRoleId(resellerData.getReseller?.id);
    }
  }, [resellerData]);

  useEffect(() => {
    if (subResellerData) {
      setUserRoleId(subResellerData.getSubReseller?.id || "");
    }
  }, [subResellerData]);

  useEffect(() => {
    if (customerData) {
      setUserRoleId(customerData.customerByUserId?.id || "");
    }
  }, [customerData]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingPermission ? "Update" : "Create"} User Permission
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <Autocomplete
                value={actionValue}
                onChange={(event: any, newValue: string | null) => {
                  setActionValue(newValue);
                  setModelValue(null);
                }}
                inputValue={inputActionValue}
                onInputChange={(event, newInputValue) => {
                  setInputActionValue(newInputValue);
                  setValue("action", newInputValue);
                }}
                id="controllable-states-demo"
                options={optionsAction || []}
                renderInput={(params) => (
                  <TextField {...params} label="Action" />
                )}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={modelValue}
                onChange={(event: any, newValue: string | null) => {
                  setModelValue(newValue);
                }}
                inputValue={inputModelValue}
                onInputChange={(event, newInputValue) => {
                  setInputModelValue(newInputValue);
                  setValue("model", newInputValue);
                }}
                id="controllable-states-demo"
                options={optionsModel || []}
                renderInput={(params) => (
                  <TextField {...params} label="Model" />
                )}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              {!editingPermission && (
                <ControlledSelectModalField
                  name="userId"
                  control={control}
                  textFieldProps={{
                    label: "Give Permission To",
                    fullWidth: true,
                  }}
                  query={usersQuery}
                  variables={
                    authUser?.role === "SuperAdmin"
                      ? {}
                      : { userRoleId: userRoleId }
                  }
                  labelsExractor={(item) => ({
                    primary:
                      authUser?.role === "SuperAdmin"
                        ? item.name
                        : item.user.name || "",
                    secondary:
                      authUser?.role === "SuperAdmin"
                        ? item.role
                        : item.user.role || "",
                  })}
                />
              )}
              {editingPermission && (
                <TextField
                  label="Given to"
                  value={
                    editingPermission?.user?.name +
                    " (" +
                    editingPermission?.user?.role +
                    ")"
                  }
                  fullWidth
                  disabled
                />
              )}
            </Grid>

            {authUser?.role === "SuperAdmin" && (
              <Grid item xs={12}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="As Super Admin, I want to assign this permission to someone else"
                  />
                </FormGroup>
                {!checked && (
                  <ControlledSelectModalField
                    name="assignedByUserId"
                    control={control}
                    initialValue={
                      editingPermission?.permissionAssignedBy as UsersPaginatedItemFragment
                    }
                    textFieldProps={{
                      label: "Assigned By",
                      fullWidth: true,
                    }}
                    query={GetUsersPaginatedDocument}
                    labelsExractor={(item) => ({
                      primary: item.name,
                      secondary: item.role,
                    })}
                  />
                )}
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserPermissionFormModal;

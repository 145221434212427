import { useQuery } from "@apollo/client";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemIcon,
  Typography,
  DialogActions,
  Button,
} from "@mui/material";
import { Pagination } from "@mui/lab";
import { DocumentNode } from "graphql";
import React, { useMemo, useState } from "react";

import { CheckRounded } from "@mui/icons-material";
import SearchField from "./SearchField";
import useDidMountEffect from "../hooks/useDidMountEffect";
import { getObjectFirstKey } from "../utils";

export interface SelectItem {
  id: string;
  [key: string]: any;
}
export interface SelectModalProps<ItemType extends SelectItem> {
  title?: string;

  limit?: number;
  open: boolean;
  query: DocumentNode;
  onClose: () => void;
  initialValue?: ItemType;
  labelsExractor: (item: ItemType) => { primary: string; secondary?: string };
  onItemChange: (item?: ItemType) => void;
  renderClear?: boolean;
  variables?: any;
}

const SelectModal = <ItemType extends SelectItem>({
  limit = 5,

  open,

  initialValue,
  onItemChange,

  onClose,
  query,
  title = "Select an Item",

  renderClear,
  variables,
  labelsExractor,
}: SelectModalProps<ItemType>) => {
  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(0);
  const [selectedItem, setSelectedItem] = useState<ItemType | undefined>(
    initialValue
  );

  const { data, refetch } = useQuery(query, {
    variables: { ...variables, limit },
  });

  const { nodes, total } = useMemo(() => {
    if (!data) return { nodes: [] as ItemType[], total: 0 };
    const resultKey = getObjectFirstKey(data);
    if (!resultKey) return { nodes: [] as ItemType[], total: 0 };
    const possibleNodes: ItemType[] = data[resultKey].nodes;
    return { nodes: possibleNodes, total: data[resultKey].totalsCount };
  }, [data]);
  //   const { getValet, valet: foundValet } = useGetValetSelectItemLazy();

  const onItemClick = (item?: ItemType) => () => {
    // console.log({ item });
    setSelectedItem(item);
    if (onItemChange) onItemChange(item);
    onClose();
  };
  const onPageChange = (page: number) => {
    refetch({
      ...variables,
      limit,
      offset: (page === 0 ? page : page - 1) * limit,
      search,
    });
    setCurrentPage(page === 0 ? page : page - 1);
  };

  const onClearClick = () => {
    onItemClick(undefined)();
  };
  // console.log(initialValue);
  // useDidMountEffect(() => {
  //   if (initialValue) {
  //     setSelectedItem(initialValue);
  //     if (onItemChange) {
  //       onItemChange(initialValue);
  //     }
  //     //   getValet(initialValue);
  //   }
  // }, [initialValue?.id, onItemChange]);

  useDidMountEffect(() => {
    refetch({ ...variables, search, offset: limit * currentPage, limit });
  }, [search, currentPage, variables, limit]);

  const renderItem = (item: ItemType) => {
    const { primary, secondary } = labelsExractor(item);
    return (
      <ListItem
        key={item.id}
        button
        onClick={onItemClick(item)}
        style={{ borderRadius: 20 }}
      >
        <ListItemAvatar>
          <Avatar>{primary.charAt(0)}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
        {item.id === selectedItem?.id && (
          <ListItemIcon>
            <CheckRounded color="secondary" />
          </ListItemIcon>
        )}
      </ListItem>
    );
  };

  //   useEffect(() => {
  //     if (foundValet && !selectedValet) {
  //       // helpers.setValue(foundValet.id);
  //       setSelectedValet(foundValet);
  //     }
  //   }, [foundValet, selectedValet]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <SearchField onChange={(e) => setSearch(e.target.value)} />

        {nodes && nodes.length ? (
          <>
            <List>{nodes.map(renderItem)}</List>
            <Pagination
              count={Math.ceil(total / limit)}
              page={currentPage}
              onChange={(e, v) => onPageChange(v)}
              hidePrevButton
              hideNextButton
              variant="outlined"
            />
          </>
        ) : (
          <>
            <Typography>No items have been found</Typography>
            <Pagination
              count={Math.ceil(total / limit)}
              page={currentPage}
              onChange={(e, v) => onPageChange(v)}
              hidePrevButton
              hideNextButton
              variant="outlined"
              color="secondary"
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {renderClear && (
          <Button onClick={onClearClick} color="primary">
            Clear
          </Button>
        )}

        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectModal;

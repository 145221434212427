import { Typography } from "@mui/material";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import {
  DeletePermissionUserDocument,
  GetPermissionsUsersPaginatedDocument,
  PermissionsFragment,
  useGetPermissionsUsersPaginatedLazyQuery,
} from "../../../apollo/users/queries.generated";
import DeleteModal from "../../../shared/components/DeleteModal";
import PageContainer from "../../../shared/components/PageContainer";
import TableCard from "../../../shared/components/TableCard";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import UserPermissionFormModal from "./UserPermissionsFormModal";
import UserPermissionTable from "./UserPermissionTable";

interface UserPermissionPageProps {}

const UserPermissionPage: React.FC<UserPermissionPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedPermission, setSelectedPermission] =
    useState<PermissionsFragment>();
  const authUser = useAuthUser();
  const [refetchPermissions] = useGetPermissionsUsersPaginatedLazyQuery({
    // variables: authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id },
  });

  const handleFormClose = async () => {
    setSelectedPermission(undefined);
    // await refetchPermissions();
    setShowFormModal(false);
  };

  const handleOpenFormModal = () => {
    if (
      authUser?.permissions?.length === 0 &&
      authUser?.role !== "SuperAdmin"
    ) {
      return toast.error("You don't have zone permissions in your account");
    }
    setShowFormModal(true);
  };

  const handleDeleteModalClose = () => {
    setSelectedPermission(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <UserPermissionFormModal
        editingPermission={selectedPermission}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeletePermissionUserDocument}
        updateQueries={[GetPermissionsUsersPaginatedDocument]}
        variables={{ id: selectedPermission?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Reseller"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Users Permission
        </Typography>
        <UserPermissionTable
          onAddClick={() => handleOpenFormModal()}
          onEditPress={(item) => {
            setSelectedPermission(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedPermission(item);
            handleOpenFormModal();
          }}
        />
      </TableCard>
    </PageContainer>
  );
};

export default UserPermissionPage;

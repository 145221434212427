import { colors, Grid, Typography } from "@mui/material";
import { useGetZoneByNameQuery } from "../../../apollo/zones/queries.generated";
import InfoSection, { InfoItem } from "../../../shared/components/InfoSection";
import PageContainer from "../../../shared/components/PageContainer";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import LoadingPage from "../../home/LoadingPage";
import AbcIcon from "@mui/icons-material/Abc";
import DomainIcon from "@mui/icons-material/Domain";
import dayjs from "dayjs";

interface ZoneDetailsProps {
  zoneName: string;
  recordId?: string;
}

const ZoneDetails: React.FC<ZoneDetailsProps> = ({ zoneName, recordId }) => {
  const authUser = useAuthUser();

  const { data, loading, error } = useGetZoneByNameQuery({
    variables: {
      zoneName: zoneName,
    },
  });

  if (error) return <div>Error</div>;
  if (!data) return <LoadingPage />;

  const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  };

  const items: InfoItem[] = [
    {
      icon: "N",
      label: "Name",
      value: `${data.getZoneByName.name}`,
      color: colors.orange[500],
    },
    {
      icon: "S",
      label: "Status",
      value: `${data.getZoneByName.status}`,
      color: colors.green[500],
    },
    {
      icon: "T",
      label: "Type",
      value: `${data.getZoneByName.type}`,
      color: colors.blue[500],
    },
    {
      icon: "NS",
      label: "Name Servers",
      value: `${data.getZoneByName.nameservers.join(", ")}`,
      color: colors.purple[500],
    },
    {
      icon: "A",
      label: "Activated At",
      value: dayjs(data.getZoneByName.activatedOn).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      color: colors.pink[500],
    },
    {
      icon: "C",
      label: "Created At",
      value: dayjs(data.getZoneByName.createdOn).format("YYYY-MM-DD HH:mm:ss"),
      color: colors.red[500],
    },
    {
      icon: "U",
      label: "Updated At",
      value: dayjs(data.getZoneByName.modifiedOn).format("YYYY-MM-DD HH:mm:ss"),
      color: colors.yellow[700],
    },
  ];

  return (
    <PageContainer>
      <Grid container justifyContent="space-between">
        <Grid item marginBottom={"20px"}>
          <Typography
            variant="h5"
            textAlign="start"
            fontWeight="bold"
            color="secondary"
            sx={{ marginRight: "10px" }}
          >
            Zone Information
          </Typography>
        </Grid>

        <Grid item>
          <InfoSection items={items} />
        </Grid>
        {/* 
      {students && (
        <Box>
          <Typography
            variant="h6"
            textAlign="start"
            fontWeight="bold"
            color="secondary"
            sx={{ marginRight: "10px", marginTop: "10px" }}
          >
            Assigned Students
          </Typography>
          <InfoSection items={studentsItems} />
        </Box>
      )} */}
      </Grid>
    </PageContainer>
  );
};

export default ZoneDetails;

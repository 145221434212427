import { Container } from "@mui/material";
import React from "react";
import useAuthUser from "../../shared/hooks/useAuthUser";
import ResellerPage from "../resellers/ResellerPage";
import SubResellerPage from "../subresellers/SubResellerPage";
import AdminContent from "./components/AdminContent";
import CostumerPage from "../customers/CustomerPage";
import ManageZonesPage from "../zones/ManageZones";
// import useAuthUser from "../../shared/hooks/useAuthUser";
// import ParentsContent from "./components/ParentsContent";
// import SystemManagersContent from "./components/SystemManagersContent";

interface DashboardHomePageProps {}

const DashboardHomePage: React.FC<DashboardHomePageProps> = ({}) => {
  const authUser = useAuthUser();
  const getContent = () => {
    const role = authUser?.role;

    if (role === "SuperAdmin") return <AdminContent />;

    return <ManageZonesPage />;
    // return <DashboardHomePage />;
  };

  return (
    <Container sx={{ paddingTop: 5, paddingBottom: 5 }}>
      {getContent()}
    </Container>
  );
};

export default DashboardHomePage;

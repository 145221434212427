import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ZoneFragment = { id: string, name: string, status: string, type: string, nameservers: Array<string>, originalNameservers?: Array<string> | null, originalRegistrar?: string | null, activatedOn: string, createdOn: string, modifiedOn: string };

export type GetZoneListPaginatedQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetZoneListPaginatedQuery = { getZoneListPaginated: { totalsCount: number, nodes?: Array<{ id: string, name: string, status: string, type: string, nameservers: Array<string>, originalNameservers?: Array<string> | null, originalRegistrar?: string | null, activatedOn: string, createdOn: string, modifiedOn: string }> | null } };

export type GetZoneByIdQueryVariables = Types.Exact<{
  zoneId: Types.Scalars['String'];
}>;


export type GetZoneByIdQuery = { getZoneById: { id: string, name: string, status: string, type: string, nameservers: Array<string>, originalNameservers?: Array<string> | null, originalRegistrar?: string | null, activatedOn: string, createdOn: string, modifiedOn: string } };

export type GetZoneByNameQueryVariables = Types.Exact<{
  zoneName: Types.Scalars['String'];
}>;


export type GetZoneByNameQuery = { getZoneByName: { id: string, name: string, status: string, type: string, nameservers: Array<string>, originalNameservers?: Array<string> | null, originalRegistrar?: string | null, activatedOn: string, createdOn: string, modifiedOn: string } };

export const ZoneFragmentDoc = gql`
    fragment Zone on ZoneDTO {
  id
  name
  status
  type
  nameservers
  originalNameservers
  originalRegistrar
  activatedOn
  createdOn
  modifiedOn
}
    `;
export const GetZoneListPaginatedDocument = gql`
    query getZoneListPaginated($limit: Int, $page: Int) {
  getZoneListPaginated(limit: $limit, page: $page) {
    nodes {
      ...Zone
    }
    totalsCount
  }
}
    ${ZoneFragmentDoc}`;

/**
 * __useGetZoneListPaginatedQuery__
 *
 * To run a query within a React component, call `useGetZoneListPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZoneListPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZoneListPaginatedQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetZoneListPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetZoneListPaginatedQuery, GetZoneListPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZoneListPaginatedQuery, GetZoneListPaginatedQueryVariables>(GetZoneListPaginatedDocument, options);
      }
export function useGetZoneListPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZoneListPaginatedQuery, GetZoneListPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZoneListPaginatedQuery, GetZoneListPaginatedQueryVariables>(GetZoneListPaginatedDocument, options);
        }
export type GetZoneListPaginatedQueryHookResult = ReturnType<typeof useGetZoneListPaginatedQuery>;
export type GetZoneListPaginatedLazyQueryHookResult = ReturnType<typeof useGetZoneListPaginatedLazyQuery>;
export type GetZoneListPaginatedQueryResult = Apollo.QueryResult<GetZoneListPaginatedQuery, GetZoneListPaginatedQueryVariables>;
export const GetZoneByIdDocument = gql`
    query getZoneById($zoneId: String!) {
  getZoneById(zoneId: $zoneId) {
    ...Zone
  }
}
    ${ZoneFragmentDoc}`;

/**
 * __useGetZoneByIdQuery__
 *
 * To run a query within a React component, call `useGetZoneByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZoneByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZoneByIdQuery({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *   },
 * });
 */
export function useGetZoneByIdQuery(baseOptions: Apollo.QueryHookOptions<GetZoneByIdQuery, GetZoneByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZoneByIdQuery, GetZoneByIdQueryVariables>(GetZoneByIdDocument, options);
      }
export function useGetZoneByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZoneByIdQuery, GetZoneByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZoneByIdQuery, GetZoneByIdQueryVariables>(GetZoneByIdDocument, options);
        }
export type GetZoneByIdQueryHookResult = ReturnType<typeof useGetZoneByIdQuery>;
export type GetZoneByIdLazyQueryHookResult = ReturnType<typeof useGetZoneByIdLazyQuery>;
export type GetZoneByIdQueryResult = Apollo.QueryResult<GetZoneByIdQuery, GetZoneByIdQueryVariables>;
export const GetZoneByNameDocument = gql`
    query getZoneByName($zoneName: String!) {
  getZoneByName(zoneName: $zoneName) {
    ...Zone
  }
}
    ${ZoneFragmentDoc}`;

/**
 * __useGetZoneByNameQuery__
 *
 * To run a query within a React component, call `useGetZoneByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZoneByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZoneByNameQuery({
 *   variables: {
 *      zoneName: // value for 'zoneName'
 *   },
 * });
 */
export function useGetZoneByNameQuery(baseOptions: Apollo.QueryHookOptions<GetZoneByNameQuery, GetZoneByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZoneByNameQuery, GetZoneByNameQueryVariables>(GetZoneByNameDocument, options);
      }
export function useGetZoneByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZoneByNameQuery, GetZoneByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZoneByNameQuery, GetZoneByNameQueryVariables>(GetZoneByNameDocument, options);
        }
export type GetZoneByNameQueryHookResult = ReturnType<typeof useGetZoneByNameQuery>;
export type GetZoneByNameLazyQueryHookResult = ReturnType<typeof useGetZoneByNameLazyQuery>;
export type GetZoneByNameQueryResult = Apollo.QueryResult<GetZoneByNameQuery, GetZoneByNameQueryVariables>;
import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { UsersPaginatedItemFragment } from "../../apollo/users/queries.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import useAuthUser from "../../shared/hooks/useAuthUser";
import AdminUsersTable from "./components/AdminUsersTable";
import UsersTable from "./components/UsersTable";
interface UserListPageProps {}

const UserListPage: React.FC<UserListPageProps> = ({}) => {
  const authUser = useAuthUser();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUser, setSelectedUser] =
    useState<UsersPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedUser(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedUser(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      {/* <SubResellerFormModal
        editingSubReseller={selectedSubReseller}
        open={showFormModal}
        onClose={handleFormClose}
      /> */}
      {/* <DeleteModal
        open={showDeleteModal}
        // mutation={DeleteSubResellerDocument}
        updateQueries={[GetSubResellersPaginatedDocument]}
        variables={{ id: selectedSubReseller?.id }}
        onClose={handleDeleteModalClose}
        title="Delete SubReseller"
      /> */}
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Users
        </Typography>
        {authUser?.role === "SuperAdmin" ? (
          <AdminUsersTable
          // onAddClick={() => setShowFormModal(true)}
          // onEditPress={(item) => {
          //   setSelectedUser(item);
          //   setShowFormModal(true);
          // }}
          // onDeletePress={(item) => {
          //   setSelectedUser(item);
          //   setShowDeleteModal(true);
          // }}
          />
        ) : (
          <UsersTable />
        )}
      </TableCard>
    </PageContainer>
  );
};

export default UserListPage;

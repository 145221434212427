import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
  useUpdateUserMutation,
} from "../../../apollo/users/queries.generated";

import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
  useCreateResellerMutation,
} from "../../../apollo/resellers/queries.generated";
import { CreateResellerDto } from "../../../apollo/types.generated";

interface ResellersFormModalProps {
  open: boolean;
  onClose: () => void;
  editingReseller?: ResellersPaginatedItemFragment;
}

interface FormInputs extends Omit<CreateResellerDto, "password"> {
  passwordConfirmation?: string;
  password?: string;
}

const getSchema = (editingReseller?: ResellersPaginatedItemFragment) =>
  Yup.object({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email")
      .test(
        "test-mail",
        "This email address is not available",
        async (value, context) => {
          if (!value) return false;
          const result = await apolloClient.query<
            CheckEmailAvailabilityQuery,
            CheckEmailAvailabilityQueryVariables
          >({
            query: CheckEmailAvailabilityDocument,
            variables: { email: value, userId: editingReseller?.user.id },
          });

          return result.data.checkEmailAvailability;
        }
      ),
    password: editingReseller
      ? Yup.string().optional()
      : Yup.string().required("This field is required"),
    passwordConfirmation: editingReseller
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
  }).required();

const getDefaultValues = (
  reseller?: ResellersPaginatedItemFragment
): FormInputs | undefined => {
  return {
    firstName: reseller?.user.firstName || "",
    lastName: reseller?.user.lastName || "",
    email: reseller?.user.email || "",
    password: "",
    passwordConfirmation: "",
  };
};
const ResellerFormModal: React.FC<ResellersFormModalProps> = ({
  open,
  onClose,
  editingReseller,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue } = useForm<FormInputs>({
    resolver: yupResolver(getSchema(editingReseller)),
    shouldFocusError: true,
    defaultValues: getDefaultValues(editingReseller),
  });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateResellerMutation({
    refetchQueries: [GetResellersPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateUserMutation({
    refetchQueries: [GetResellersPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    const { passwordConfirmation, ...rest } = data;
    const dataToSubmit = { ...rest };
    if (editingReseller) {
      update({
        variables: {
          id: editingReseller?.user?.id,
          data: dataToSubmit,
        },
      });
      return;
    }

    if (rest.password) {
      create({
        variables: {
          data: {
            ...dataToSubmit,
            password: rest.password,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (createData) {
      toast.success("Reseller created successfully");
      reset(getDefaultValues());
      onClose();
    }

    if (createError) {
      toast.error("Error creating Reseller: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Reseller updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Reseller: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingReseller));
  }, [editingReseller]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingReseller ? "Update" : "Create"} Reseller
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email"
              />
            </Grid>

            <Grid item xs={12}>
              {!!editingReseller && (
                <Typography variant="caption" color="GrayText">
                  You can skip this field to omit changing the reseller's
                  password
                </Typography>
              )}
              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResellerFormModal;

import React from "react";
import { useRouteMatch, Switch, Route } from "react-router-dom";
import CustomersListPage from "../pages/customers/CustomersListPage";
import DashboardHomePage from "../pages/home/DashboardHomePage";
import PermissionsPage from "../pages/permissions/PermissionsPage";
import ResellersListPage from "../pages/resellers/ResellersListPage";
import MyRoles from "../pages/customRoles/MyRoles";
import SubResellersListPage from "../pages/subresellers/SubResellersListPage";
import UserListPage from "../pages/users/UserListPage";
import ZonesPage from "../pages/zones/ZonesPage";
import ZoneInfoPage from "../pages/zones/ZoneInfoPage";
import ManageZonesPage from "../pages/zones/ManageZones";

interface DashboardRoutesProps {}

const DashboardRoutes: React.FC<DashboardRoutesProps> = ({}) => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <DashboardHomePage />
      </Route>

      <Route path={path + "/zones/:zoneName"} exact>
        <ZoneInfoPage />
      </Route>

      <Route path={path + "/manageZones"} exact>
        <ManageZonesPage />
      </Route>

      <Route path={path + "/resellers"} exact>
        <ResellersListPage />
      </Route>

      <Route path={path + "/subresellers"} exact>
        <SubResellersListPage />
      </Route>

      <Route path={path + "/customers"} exact>
        <CustomersListPage />
      </Route>

      <Route path={path + "/roles"} exact>
        <MyRoles />
      </Route>

      <Route path={path + "/users"} exact>
        <UserListPage />
      </Route>

      <Route path={path + "/permissions"} exact>
        <PermissionsPage />
      </Route>
    </Switch>
  );
};

export default DashboardRoutes;

import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  RadioGroup,
  FormControlLabelProps as RadioButtonsControlLabelProps,
  useRadioGroup,
  Radio,
  FormControlLabel,
  styled,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
  useUpdateUserMutation,
} from "../../../apollo/users/queries.generated";

import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
  useCreateResellerMutation,
  useGetResellerByUserIdLazyQuery,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateCustomerDto,
  CreateResellerDto,
  CreateSubResellerDto,
} from "../../../apollo/types.generated";
import {
  GetSubResellersPaginatedDocument,
  useGetSubResellerByUserIdLazyQuery,
} from "../../../apollo/subresellers/queries.generated";
import {
  CustomerFragment,
  CustomersPaginatedDocument,
  useCreateCustomerMutation,
} from "../../../apollo/customers/queries.generated";

interface CustomerFormModalProps {
  open: boolean;
  onClose: () => void;
  editingCustomer?: CustomerFragment;
}

interface FormInputs extends Omit<CreateCustomerDto, "password"> {
  passwordConfirmation?: string;
  password?: string;
}

interface StyledFormControlLabelProps extends RadioButtonsControlLabelProps {
  checked: boolean;
}

const StyledRadioButtonsControlLabel = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
)(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

const getSchema = (editingCustomer?: CustomerFragment) =>
  Yup.object({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email")
      .test(
        "test-mail",
        "This email address is not available",
        async (value, context) => {
          if (!value) return false;
          const result = await apolloClient.query<
            CheckEmailAvailabilityQuery,
            CheckEmailAvailabilityQueryVariables
          >({
            query: CheckEmailAvailabilityDocument,
            variables: { email: value, userId: editingCustomer?.user.id },
          });

          return result.data.checkEmailAvailability;
        }
      ),
    password: editingCustomer
      ? Yup.string().optional()
      : Yup.string().required("This field is required"),
    passwordConfirmation: editingCustomer
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
    reseller: Yup.string().optional(),
    subReseller: Yup.string().optional(),
  }).required();

const getDefaultValues = (
  customer?: CustomerFragment
): FormInputs | undefined => {
  return {
    firstName: customer?.user.firstName || "",
    lastName: customer?.user.lastName || "",
    email: customer?.user.email || "",
    password: "",
    passwordConfirmation: "",
    // resellerId: customer?.reseller!.user.name || "",
    // subResellerId: customer?.subReseller!.user.name || "",
  };
};
const CustomerFormModal: React.FC<CustomerFormModalProps> = ({
  open,
  onClose,
  editingCustomer,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue } = useForm<FormInputs>({
    resolver: yupResolver(getSchema(editingCustomer)),
    shouldFocusError: true,
    defaultValues: getDefaultValues(editingCustomer),
  });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateCustomerMutation({
    refetchQueries: [CustomersPaginatedDocument],
  });

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateUserMutation({
    refetchQueries: [CustomersPaginatedDocument],
  });

  const [contractor, setContractor] = React.useState<String>("reseller");
  const [getReseller, { data: resellerData }] =
    useGetResellerByUserIdLazyQuery();
  const [getSubReseller, { data: subResellerData }] =
    useGetSubResellerByUserIdLazyQuery();

  const onSubmit = (data: FormInputs) => {
    const { passwordConfirmation, ...rest } = data;
    const dataToSubmit = { ...rest };

    if (editingCustomer) {
      const { resellerId, subResellerId, ...userData } = dataToSubmit;
      update({
        variables: {
          id: editingCustomer?.user?.id,
          data: userData,
        },
      });
      return;
    }

    if (rest.password) {
      create({
        variables: {
          data: {
            ...dataToSubmit,
            password: rest.password,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (createData) {
      toast.success("Customer created successfully");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error creating Customer: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("Customer updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating Customer: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingCustomer));
  }, [editingCustomer]);

  function ContractorTypeControlLabel(props: RadioButtonsControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    useEffect(() => {
      if (radioGroup) {
        setContractor(radioGroup.value);
      }
    }, [radioGroup?.value]);

    return <StyledRadioButtonsControlLabel checked={checked} {...props} />;
  }

  useEffect(() => {
    if (authUser) {
      if (authUser?.role === "SubReseller") {
        getSubReseller({
          variables: {
            id: authUser?.id,
          },
        });
      }

      if (authUser?.role === "Reseller") {
        getReseller({
          variables: {
            id: authUser?.id,
          },
        });
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (resellerData) {
      setValue("resellerId", resellerData?.getReseller?.id);
    }
  }, [resellerData]);

  useEffect(() => {
    if (subResellerData) {
      setValue("subResellerId", subResellerData?.getSubReseller?.id);
    }
  }, [subResellerData]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingCustomer ? "Update" : "Create"} Customer
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email"
              />
            </Grid>

            <Grid item xs={12}>
              {!!editingCustomer && (
                <Typography variant="caption" color="GrayText">
                  You can skip this field to omit changing the Customer's
                  password
                </Typography>
              )}
              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation"
              />
            </Grid>
            {authUser?.role === "SuperAdmin" && !editingCustomer && (
              <Grid item xs={12}>
                <Typography>Select the contractor</Typography>
                <RadioGroup row name="contractor" defaultValue="reseller">
                  <ContractorTypeControlLabel
                    value="reseller"
                    label="Reseller"
                    control={<Radio />}
                  />
                  <ContractorTypeControlLabel
                    value="subReseller"
                    label="Sub Reseller"
                    control={<Radio />}
                  />
                </RadioGroup>
              </Grid>
            )}
            {contractor === "reseller" &&
            !editingCustomer &&
            authUser?.role === "SuperAdmin" ? (
              <Grid item xs={12}>
                <ControlledSelectModalField
                  name="resellerId"
                  control={control}
                  // initialValue={
                  //   editingCustomer?.reseller as ResellersPaginatedItemFragment
                  // }
                  textFieldProps={{
                    label: "Reseller",
                    fullWidth: true,
                  }}
                  query={GetResellersPaginatedDocument}
                  labelsExractor={(item) => ({
                    primary: item.user.name,
                  })}
                />
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                hidden={!!editingCustomer || authUser?.role !== "SuperAdmin"}
              >
                <ControlledSelectModalField
                  name="subResellerId"
                  control={control}
                  // initialValue={
                  //   editingCustomer?.subReseller as SubResellersPaginatedItemFragment
                  // }
                  textFieldProps={{
                    label: "Sub Reseller",
                    fullWidth: true,
                  }}
                  query={GetSubResellersPaginatedDocument}
                  labelsExractor={(item) => ({
                    primary: item.user.name,
                  })}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default CustomerFormModal;

import React, { useEffect, useState } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Autocomplete,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import { UsersPaginatedItemFragment } from "../../../apollo/users/queries.generated";

import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetAllResellerZonesPaginatedDocument,
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
  ResellerZonesPaginatedItemFragment,
  useAddZoneToResellerMutation,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateResellerZoneDto,
  CreateUpdateDnsdto,
  DnsRecordDto,
  ResellerZoneDto,
} from "../../../apollo/types.generated";
import { useQuery } from "@apollo/client";
import { GetZoneByNameDocument } from "../../../apollo/zones/queries.generated";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DeleteDnsRecordDocument,
  DnsRecordFragment,
  GetZoneDnsRecordsDocument,
  useCreateDnsRecordMutation,
  useDeleteDnsRecordMutation,
  useGetDnsRecordLazyQuery,
  useUpdateDnsRecordMutation,
} from "../../../apollo/dns/queries.generated";
import DeleteModal from "../../../shared/components/DeleteModal";

interface AddDNSModalProps {
  open: boolean;
  onClose: () => void;
  zoneName: string;
  zoneId: string;
  editingDNS?: DnsRecordFragment;
}
interface FormInputs extends CreateUpdateDnsdto {}

const auxOptionsDNSType = [
  "A",
  "AAAA",
  "CNAME",
  "MX",
  "NS",
  "PTR",
  "CERT",
  "SRV",
  "TXT",
  "SOA",
];

const getSchema = (editingDNS?: DnsRecordFragment) =>
  Yup.object({
    name: Yup.string().required("DNS name is required"),
    type: Yup.string().required("Type is required"),
    content: Yup.string().required("Content is required"),
    ttl: Yup.number().required("TTL is required"),
    priority: Yup.number().required("Priority is required"),
    proxied: Yup.boolean().required("Proxied is required"),
    comment: Yup.string().optional(),
  }).required();

const getDefaultValues = (DNS?: DnsRecordFragment): FormInputs | undefined => {
  return {
    name: DNS?.name || "",
    type: DNS?.type || "",
    content: DNS?.content || "",
    ttl: DNS?.ttl || 0,
    priority: DNS?.priority || 0,
    proxied: DNS?.proxied || true,
    comment: DNS?.comment || "",
    zoneId: DNS?.zone_id || "",
    recordId: DNS?.id || "",
  };
};
const AddDNSModal: React.FC<AddDNSModalProps> = ({
  open,
  onClose,
  editingDNS,
  zoneName,
  zoneId,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema(editingDNS)),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingDNS),
    }
  );
  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateDnsRecordMutation({
    refetchQueries: [
      {
        query: GetZoneDnsRecordsDocument,
        variables: { limit: 10, page: 1, zoneName: zoneName },
      },
    ],
  });

  const [optionsDNSType, setoptionsDNSType] = React.useState<string[]>();

  const [dnsTypeValue, setdnsTypeValue] = React.useState<string | null>(
    optionsDNSType ? optionsDNSType[0] : null
  );

  const [inputDnsTypeValue, setinputDnsTypeValue] = React.useState("");

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateDnsRecordMutation({
    refetchQueries: [
      {
        query: GetZoneDnsRecordsDocument,
        variables: { limit: 10, page: 1, zoneName: zoneName },
      },
    ],
  });

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onSubmit = (data: FormInputs) => {
    const { ...rest } = data;
    const dataToSubmit = { ...rest };

    if (data.type === "MX" || data.type === "SRV" || data.type === "URI") {
      if (data.priority === 0) {
        toast.error("Priority is required");
        return;
      }
      delete dataToSubmit.proxied;
    } else {
      delete dataToSubmit.priority;
    }

    if (!editingDNS) {
      delete dataToSubmit.recordId;
      dataToSubmit.zoneId = zoneId;
    }

    if (editingDNS) {
      update({
        variables: {
          data: {
            ...dataToSubmit,
          },
        },
      });
      return;
    }

    create({
      variables: {
        data: {
          ...dataToSubmit,
        },
      },
    });
  };

  useEffect(() => {
    if (authUser?.role === "SuperAdmin") {
      setoptionsDNSType(auxOptionsDNSType);
      return;
    }

    if (authUser?.permissions?.length) {
      const auxPermissions = authUser?.permissions?.filter(
        (permission) => permission.action === "Manage"
      );

      if (auxPermissions) {
        const DNSTypes = auxPermissions?.map((permission) => {
          if (permission.model.includes("DNS_Record")) {
            return permission.model.replace("DNS_Record_", "");
          }

          return undefined;
        });

        if (DNSTypes) {
          setoptionsDNSType(
            DNSTypes.filter((item) => item !== undefined) as string[]
          );
        }
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (createData) {
      toast.success("DNS added successfully");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error adding DNS: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("DNS updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating DNS: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingDNS));
  }, [editingDNS]);

  const handleDeleteModalClose = () => {
    reset();
    setShowDeleteModal(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="sm"
      fullWidth
    >
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteDnsRecordDocument}
        updateQueries={[
          {
            query: GetZoneDnsRecordsDocument,
            variables: { limit: 10, page: 1, zoneName: zoneName },
          },
        ]}
        variables={{
          zoneId: zoneId,
          recordId: editingDNS?.id || "",
        }}
        onClose={handleDeleteModalClose}
        title="Delete DNS Record"
      />
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>{editingDNS ? "Update" : "Create"} DNS</DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="content"
                control={control}
                label="Content"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField name="name" control={control} label="Name" />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                value={editingDNS ? editingDNS.type : dnsTypeValue}
                onChange={(event: any, newValue: string | null) => {
                  setdnsTypeValue(newValue);
                }}
                inputValue={editingDNS ? editingDNS.type : inputDnsTypeValue}
                onInputChange={(event, newInputValue) => {
                  setinputDnsTypeValue(newInputValue);
                  setValue("type", newInputValue);
                }}
                options={optionsDNSType ? optionsDNSType : []}
                renderInput={(params) => <TextField {...params} label="Type" />}
                fullWidth
                disabled={editingDNS ? true : false}
              />
            </Grid>
            <Grid item xs={12}>
              {watch("type") === ("MX" || "SRV" || "URI") ? (
                <ControlledTextField
                  name="priority"
                  control={control}
                  label="Priority"
                />
              ) : (
                <Autocomplete
                  value={
                    editingDNS
                      ? editingDNS.proxied.toString()
                      : watch("proxied")?.toString()
                  }
                  onChange={(event: any, newValue: string | null) => {
                    setValue("proxied", newValue === "true" ? true : false);
                  }}
                  options={["true", "false"]}
                  renderInput={(params) => (
                    <TextField {...params} label="Proxied" />
                  )}
                  fullWidth
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField name="ttl" control={control} label="TTL" />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="comment"
                control={control}
                label="Comment"
              />
            </Grid>
          </Grid>

          <Grid item xs={12}></Grid>
        </DialogContent>
        <DialogActions>
          {editingDNS && (
            <Button
              onClick={() => {
                setShowDeleteModal(true);
              }}
              color="error"
            >
              Delete
            </Button>
          )}
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton
            loading={
              createLoading
              // || updateLoading
            }
            type="submit"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default AddDNSModal;

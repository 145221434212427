import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
} from "../../apollo/resellers/queries.generated";
import { DeleteUserDocument } from "../../apollo/users/queries.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import ResellersFormModal from "./components/ResellersFormModal";
import ResellersTable from "./components/ResellersTable";

interface ResellersListPageProps {}

const ResellersListPage: React.FC<ResellersListPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedReseller, setSelectedReseller] =
    useState<ResellersPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedReseller(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedReseller(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <ResellersFormModal
        editingReseller={selectedReseller}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteUserDocument}
        updateQueries={[GetResellersPaginatedDocument]}
        variables={{ id: selectedReseller?.user?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Reseller"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Resellers
        </Typography>
        <ResellersTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedReseller(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedReseller(item);
            setShowDeleteModal(true);
          }}
        />
      </TableCard>
    </PageContainer>
  );
};

export default ResellersListPage;

import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PermissionsFragment = { id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null };

export type CustomRoleFragment = { id: string, name: string, desc: string, createdAt: any, updatedAt: any, user: { id: string, name: string }, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null };

export type AssignedCustomRoleFragment = { id: string, createdAt: any, updatedAt: any, customRoles: { id: string, name: string, desc: string, createdAt: any, updatedAt: any, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } };

export type UserFragment = { id: string, name: string, firstName: string, lastName: string, email: string, role: string, createdAt: any, updatedAt: any, isDisabled: boolean, zones?: Array<string> | null, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null, customRole?: { id: string, createdAt: any, updatedAt: any, customRoles: { id: string, name: string, desc: string, createdAt: any, updatedAt: any, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } } | null };

export type UsersLinkedItemFragment = { id: string, role: string, roleId: string, user: { id: string, name: string, firstName: string, lastName: string, email: string, role: string, createdAt: any, updatedAt: any, isDisabled: boolean, zones?: Array<string> | null, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null, customRole?: { id: string, createdAt: any, updatedAt: any, customRoles: { id: string, name: string, desc: string, createdAt: any, updatedAt: any, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } } | null } };

export type UsersPaginatedItemFragment = { id: string, name: string, firstName: string, lastName: string, email: string, role: string, createdAt: any, updatedAt: any, isDisabled: boolean, zones?: Array<string> | null, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null, customRole?: { id: string, createdAt: any, updatedAt: any, customRoles: { id: string, name: string, desc: string, createdAt: any, updatedAt: any, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } } | null };

export type PermissionsPaginatedItemFragment = { id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null };

export type CheckEmailAvailabilityQueryVariables = Types.Exact<{
  email: Types.Scalars['String'];
  userId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CheckEmailAvailabilityQuery = { checkEmailAvailability: boolean };

export type GetUsersPaginatedQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  sortDirection?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUsersPaginatedQuery = { usersPaginated: { totalsCount: number, nodes?: Array<{ id: string, name: string, firstName: string, lastName: string, email: string, role: string, createdAt: any, updatedAt: any, isDisabled: boolean, zones?: Array<string> | null, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null, customRole?: { id: string, createdAt: any, updatedAt: any, customRoles: { id: string, name: string, desc: string, createdAt: any, updatedAt: any, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } } | null }> | null } };

export type GetUserQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type GetUserQuery = { User: { id: string, name: string, firstName: string, lastName: string, email: string, role: string, createdAt: any, updatedAt: any, isDisabled: boolean, zones?: Array<string> | null, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null, customRole?: { id: string, createdAt: any, updatedAt: any, customRoles: { id: string, name: string, desc: string, createdAt: any, updatedAt: any, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } } | null } };

export type CreateUserMutationVariables = Types.Exact<{
  data: Types.CreateUserDto;
}>;


export type CreateUserMutation = { createUser: { id: string, createdAt: any, updatedAt: any } };

export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  data: Types.UpdateUserDto;
}>;


export type UpdateUserMutation = { updateUser: { id: string } };

export type DeleteUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteUserMutation = { deleteUser: boolean };

export type UpdateUserStatusMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  isDisabled: Types.Scalars['Boolean'];
}>;


export type UpdateUserStatusMutation = { updateUserStatus: { id: string, isDisabled: boolean } };

export type GetUserCustomRolesPaginatedQueryVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['String']>;
  search?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  sortDirection?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetUserCustomRolesPaginatedQuery = { getUserCreatedCustomRoles?: { totalsCount: number, nodes?: Array<{ id: string, name: string, desc: string, createdAt: any, updatedAt: any, user: { id: string, name: string }, permissions?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null }> | null } | null };

export type CreateCustomRoleMutationVariables = Types.Exact<{
  data: Types.CreateCustomRolesDto;
}>;


export type CreateCustomRoleMutation = { createCustomRole: { id: string, name: string, desc: string, permissions?: Array<{ id: string, action: string, model: string }> | null } };

export type UpdateCustomRoleMutationVariables = Types.Exact<{
  data: Types.UpdateCustomRolesDto;
}>;


export type UpdateCustomRoleMutation = { updateCustomRole: boolean };

export type DeleteCustomRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeleteCustomRoleMutation = { deleteCustomRole: boolean };

export type CreateCustomRolePermissionMutationVariables = Types.Exact<{
  data: Types.CreatePermissionsDto;
}>;


export type CreateCustomRolePermissionMutation = { createCustomRolePermission: boolean };

export type CreateUserPermissionMutationVariables = Types.Exact<{
  data: Types.CreatePermissionsDto;
}>;


export type CreateUserPermissionMutation = { createUserPermission: boolean };

export type AssignCustomRoleToUserMutationVariables = Types.Exact<{
  data: Types.AssignCustomRoleDto;
}>;


export type AssignCustomRoleToUserMutation = { assignCustomRoleToUser: boolean };

export type GetPermissionsCustomRolesPaginatedQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  sortDirection?: Types.InputMaybe<Types.Scalars['String']>;
  userId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetPermissionsCustomRolesPaginatedQuery = { permissionsCustomRolesPaginated?: { totalsCount: number, nodes?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } | null };

export type GetPermissionsUsersPaginatedQueryVariables = Types.Exact<{
  search?: Types.InputMaybe<Types.Scalars['String']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  sortBy?: Types.InputMaybe<Types.Scalars['String']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  sortDirection?: Types.InputMaybe<Types.Scalars['String']>;
  userId?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type GetPermissionsUsersPaginatedQuery = { permissionsUserPaginated?: { totalsCount: number, nodes?: Array<{ id: string, action: string, model: string, createdAt: any, updatedAt: any, customRoles?: { id: string, name: string } | null, user?: { id: string, name: string, role: string } | null, permissionAssignedBy?: { id: string, name: string, role: string } | null }> | null } | null };

export type UpdatePermissionUserMutationVariables = Types.Exact<{
  data: Types.UpdatePermissionsDto;
}>;


export type UpdatePermissionUserMutation = { updatePermission: boolean };

export type DeletePermissionUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeletePermissionUserMutation = { deletePermission: boolean };

export type UpdatePermissionCustomRoleMutationVariables = Types.Exact<{
  data: Types.UpdatePermissionsDto;
}>;


export type UpdatePermissionCustomRoleMutation = { updateCustomRolePermission: boolean };

export type DeletePermissionsCustomRoleMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;


export type DeletePermissionsCustomRoleMutation = { deleteCustomRolePermission: boolean };

export const PermissionsFragmentDoc = gql`
    fragment permissions on PermissionsDTO {
  id
  action
  model
  createdAt
  updatedAt
  customRoles {
    id
    name
  }
  user {
    id
    name
    role
  }
  permissionAssignedBy {
    id
    name
    role
  }
}
    `;
export const CustomRoleFragmentDoc = gql`
    fragment customRole on CustomRolesDTO {
  id
  name
  desc
  user {
    id
    name
  }
  createdAt
  updatedAt
  permissions {
    ...permissions
  }
}
    ${PermissionsFragmentDoc}`;
export const AssignedCustomRoleFragmentDoc = gql`
    fragment assignedCustomRole on AssignedCustomRoleDTO {
  id
  customRoles {
    id
    name
    desc
    createdAt
    updatedAt
    permissions {
      ...permissions
    }
  }
  createdAt
  updatedAt
}
    ${PermissionsFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on UserDTO {
  id
  name
  firstName
  lastName
  email
  role
  createdAt
  updatedAt
  isDisabled
  permissions {
    ...permissions
  }
  customRole {
    ...assignedCustomRole
  }
  zones
}
    ${PermissionsFragmentDoc}
${AssignedCustomRoleFragmentDoc}`;
export const UsersLinkedItemFragmentDoc = gql`
    fragment UsersLinkedItem on UsersLinkedDTO {
  id
  user {
    ...User
  }
  role
  roleId
}
    ${UserFragmentDoc}`;
export const UsersPaginatedItemFragmentDoc = gql`
    fragment UsersPaginatedItem on UserDTO {
  ...User
}
    ${UserFragmentDoc}`;
export const PermissionsPaginatedItemFragmentDoc = gql`
    fragment PermissionsPaginatedItem on PermissionsDTO {
  ...permissions
}
    ${PermissionsFragmentDoc}`;
export const CheckEmailAvailabilityDocument = gql`
    query checkEmailAvailability($email: String!, $userId: String) {
  checkEmailAvailability(email: $email, userId: $userId)
}
    `;

/**
 * __useCheckEmailAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckEmailAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckEmailAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckEmailAvailabilityQuery({
 *   variables: {
 *      email: // value for 'email'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCheckEmailAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>(CheckEmailAvailabilityDocument, options);
      }
export function useCheckEmailAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>(CheckEmailAvailabilityDocument, options);
        }
export type CheckEmailAvailabilityQueryHookResult = ReturnType<typeof useCheckEmailAvailabilityQuery>;
export type CheckEmailAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckEmailAvailabilityLazyQuery>;
export type CheckEmailAvailabilityQueryResult = Apollo.QueryResult<CheckEmailAvailabilityQuery, CheckEmailAvailabilityQueryVariables>;
export const GetUsersPaginatedDocument = gql`
    query getUsersPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String) {
  usersPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...UsersPaginatedItem
    }
    totalsCount
  }
}
    ${UsersPaginatedItemFragmentDoc}`;

/**
 * __useGetUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useGetUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetUsersPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>(GetUsersPaginatedDocument, options);
      }
export function useGetUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>(GetUsersPaginatedDocument, options);
        }
export type GetUsersPaginatedQueryHookResult = ReturnType<typeof useGetUsersPaginatedQuery>;
export type GetUsersPaginatedLazyQueryHookResult = ReturnType<typeof useGetUsersPaginatedLazyQuery>;
export type GetUsersPaginatedQueryResult = Apollo.QueryResult<GetUsersPaginatedQuery, GetUsersPaginatedQueryVariables>;
export const GetUserDocument = gql`
    query getUser($id: String!) {
  User(id: $id) {
    ...UsersPaginatedItem
  }
}
    ${UsersPaginatedItemFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($data: CreateUserDTO!) {
  createUser(data: $data) {
    id
    createdAt
    updatedAt
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: String!, $data: UpdateUserDTO!) {
  updateUser(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation deleteUser($id: String!) {
  deleteUser(id: $id)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UpdateUserStatusDocument = gql`
    mutation updateUserStatus($id: String!, $isDisabled: Boolean!) {
  updateUserStatus(id: $id, isDisabled: $isDisabled) {
    id
    isDisabled
  }
}
    `;
export type UpdateUserStatusMutationFn = Apollo.MutationFunction<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;

/**
 * __useUpdateUserStatusMutation__
 *
 * To run a mutation, you first call `useUpdateUserStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserStatusMutation, { data, loading, error }] = useUpdateUserStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDisabled: // value for 'isDisabled'
 *   },
 * });
 */
export function useUpdateUserStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>(UpdateUserStatusDocument, options);
      }
export type UpdateUserStatusMutationHookResult = ReturnType<typeof useUpdateUserStatusMutation>;
export type UpdateUserStatusMutationResult = Apollo.MutationResult<UpdateUserStatusMutation>;
export type UpdateUserStatusMutationOptions = Apollo.BaseMutationOptions<UpdateUserStatusMutation, UpdateUserStatusMutationVariables>;
export const GetUserCustomRolesPaginatedDocument = gql`
    query getUserCustomRolesPaginated($userId: String, $search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String) {
  getUserCreatedCustomRoles(
    userId: $userId
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
  ) {
    nodes {
      ...customRole
    }
    totalsCount
  }
}
    ${CustomRoleFragmentDoc}`;

/**
 * __useGetUserCustomRolesPaginatedQuery__
 *
 * To run a query within a React component, call `useGetUserCustomRolesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCustomRolesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCustomRolesPaginatedQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *   },
 * });
 */
export function useGetUserCustomRolesPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetUserCustomRolesPaginatedQuery, GetUserCustomRolesPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCustomRolesPaginatedQuery, GetUserCustomRolesPaginatedQueryVariables>(GetUserCustomRolesPaginatedDocument, options);
      }
export function useGetUserCustomRolesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCustomRolesPaginatedQuery, GetUserCustomRolesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCustomRolesPaginatedQuery, GetUserCustomRolesPaginatedQueryVariables>(GetUserCustomRolesPaginatedDocument, options);
        }
export type GetUserCustomRolesPaginatedQueryHookResult = ReturnType<typeof useGetUserCustomRolesPaginatedQuery>;
export type GetUserCustomRolesPaginatedLazyQueryHookResult = ReturnType<typeof useGetUserCustomRolesPaginatedLazyQuery>;
export type GetUserCustomRolesPaginatedQueryResult = Apollo.QueryResult<GetUserCustomRolesPaginatedQuery, GetUserCustomRolesPaginatedQueryVariables>;
export const CreateCustomRoleDocument = gql`
    mutation createCustomRole($data: CreateCustomRolesDTO!) {
  createCustomRole(data: $data) {
    id
    name
    desc
    permissions {
      id
      action
      model
    }
  }
}
    `;
export type CreateCustomRoleMutationFn = Apollo.MutationFunction<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>;

/**
 * __useCreateCustomRoleMutation__
 *
 * To run a mutation, you first call `useCreateCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomRoleMutation, { data, loading, error }] = useCreateCustomRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>(CreateCustomRoleDocument, options);
      }
export type CreateCustomRoleMutationHookResult = ReturnType<typeof useCreateCustomRoleMutation>;
export type CreateCustomRoleMutationResult = Apollo.MutationResult<CreateCustomRoleMutation>;
export type CreateCustomRoleMutationOptions = Apollo.BaseMutationOptions<CreateCustomRoleMutation, CreateCustomRoleMutationVariables>;
export const UpdateCustomRoleDocument = gql`
    mutation updateCustomRole($data: UpdateCustomRolesDTO!) {
  updateCustomRole(data: $data)
}
    `;
export type UpdateCustomRoleMutationFn = Apollo.MutationFunction<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>;

/**
 * __useUpdateCustomRoleMutation__
 *
 * To run a mutation, you first call `useUpdateCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomRoleMutation, { data, loading, error }] = useUpdateCustomRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>(UpdateCustomRoleDocument, options);
      }
export type UpdateCustomRoleMutationHookResult = ReturnType<typeof useUpdateCustomRoleMutation>;
export type UpdateCustomRoleMutationResult = Apollo.MutationResult<UpdateCustomRoleMutation>;
export type UpdateCustomRoleMutationOptions = Apollo.BaseMutationOptions<UpdateCustomRoleMutation, UpdateCustomRoleMutationVariables>;
export const DeleteCustomRoleDocument = gql`
    mutation deleteCustomRole($id: String!) {
  deleteCustomRole(id: $id)
}
    `;
export type DeleteCustomRoleMutationFn = Apollo.MutationFunction<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>;

/**
 * __useDeleteCustomRoleMutation__
 *
 * To run a mutation, you first call `useDeleteCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomRoleMutation, { data, loading, error }] = useDeleteCustomRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>(DeleteCustomRoleDocument, options);
      }
export type DeleteCustomRoleMutationHookResult = ReturnType<typeof useDeleteCustomRoleMutation>;
export type DeleteCustomRoleMutationResult = Apollo.MutationResult<DeleteCustomRoleMutation>;
export type DeleteCustomRoleMutationOptions = Apollo.BaseMutationOptions<DeleteCustomRoleMutation, DeleteCustomRoleMutationVariables>;
export const CreateCustomRolePermissionDocument = gql`
    mutation createCustomRolePermission($data: CreatePermissionsDTO!) {
  createCustomRolePermission(data: $data)
}
    `;
export type CreateCustomRolePermissionMutationFn = Apollo.MutationFunction<CreateCustomRolePermissionMutation, CreateCustomRolePermissionMutationVariables>;

/**
 * __useCreateCustomRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateCustomRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomRolePermissionMutation, { data, loading, error }] = useCreateCustomRolePermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateCustomRolePermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomRolePermissionMutation, CreateCustomRolePermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomRolePermissionMutation, CreateCustomRolePermissionMutationVariables>(CreateCustomRolePermissionDocument, options);
      }
export type CreateCustomRolePermissionMutationHookResult = ReturnType<typeof useCreateCustomRolePermissionMutation>;
export type CreateCustomRolePermissionMutationResult = Apollo.MutationResult<CreateCustomRolePermissionMutation>;
export type CreateCustomRolePermissionMutationOptions = Apollo.BaseMutationOptions<CreateCustomRolePermissionMutation, CreateCustomRolePermissionMutationVariables>;
export const CreateUserPermissionDocument = gql`
    mutation createUserPermission($data: CreatePermissionsDTO!) {
  createUserPermission(data: $data)
}
    `;
export type CreateUserPermissionMutationFn = Apollo.MutationFunction<CreateUserPermissionMutation, CreateUserPermissionMutationVariables>;

/**
 * __useCreateUserPermissionMutation__
 *
 * To run a mutation, you first call `useCreateUserPermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserPermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserPermissionMutation, { data, loading, error }] = useCreateUserPermissionMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateUserPermissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserPermissionMutation, CreateUserPermissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserPermissionMutation, CreateUserPermissionMutationVariables>(CreateUserPermissionDocument, options);
      }
export type CreateUserPermissionMutationHookResult = ReturnType<typeof useCreateUserPermissionMutation>;
export type CreateUserPermissionMutationResult = Apollo.MutationResult<CreateUserPermissionMutation>;
export type CreateUserPermissionMutationOptions = Apollo.BaseMutationOptions<CreateUserPermissionMutation, CreateUserPermissionMutationVariables>;
export const AssignCustomRoleToUserDocument = gql`
    mutation assignCustomRoleToUser($data: AssignCustomRoleDTO!) {
  assignCustomRoleToUser(data: $data)
}
    `;
export type AssignCustomRoleToUserMutationFn = Apollo.MutationFunction<AssignCustomRoleToUserMutation, AssignCustomRoleToUserMutationVariables>;

/**
 * __useAssignCustomRoleToUserMutation__
 *
 * To run a mutation, you first call `useAssignCustomRoleToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignCustomRoleToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignCustomRoleToUserMutation, { data, loading, error }] = useAssignCustomRoleToUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useAssignCustomRoleToUserMutation(baseOptions?: Apollo.MutationHookOptions<AssignCustomRoleToUserMutation, AssignCustomRoleToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignCustomRoleToUserMutation, AssignCustomRoleToUserMutationVariables>(AssignCustomRoleToUserDocument, options);
      }
export type AssignCustomRoleToUserMutationHookResult = ReturnType<typeof useAssignCustomRoleToUserMutation>;
export type AssignCustomRoleToUserMutationResult = Apollo.MutationResult<AssignCustomRoleToUserMutation>;
export type AssignCustomRoleToUserMutationOptions = Apollo.BaseMutationOptions<AssignCustomRoleToUserMutation, AssignCustomRoleToUserMutationVariables>;
export const GetPermissionsCustomRolesPaginatedDocument = gql`
    query getPermissionsCustomRolesPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $userId: String) {
  permissionsCustomRolesPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    userId: $userId
  ) {
    nodes {
      ...PermissionsPaginatedItem
    }
    totalsCount
  }
}
    ${PermissionsPaginatedItemFragmentDoc}`;

/**
 * __useGetPermissionsCustomRolesPaginatedQuery__
 *
 * To run a query within a React component, call `useGetPermissionsCustomRolesPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsCustomRolesPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsCustomRolesPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPermissionsCustomRolesPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsCustomRolesPaginatedQuery, GetPermissionsCustomRolesPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsCustomRolesPaginatedQuery, GetPermissionsCustomRolesPaginatedQueryVariables>(GetPermissionsCustomRolesPaginatedDocument, options);
      }
export function useGetPermissionsCustomRolesPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsCustomRolesPaginatedQuery, GetPermissionsCustomRolesPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsCustomRolesPaginatedQuery, GetPermissionsCustomRolesPaginatedQueryVariables>(GetPermissionsCustomRolesPaginatedDocument, options);
        }
export type GetPermissionsCustomRolesPaginatedQueryHookResult = ReturnType<typeof useGetPermissionsCustomRolesPaginatedQuery>;
export type GetPermissionsCustomRolesPaginatedLazyQueryHookResult = ReturnType<typeof useGetPermissionsCustomRolesPaginatedLazyQuery>;
export type GetPermissionsCustomRolesPaginatedQueryResult = Apollo.QueryResult<GetPermissionsCustomRolesPaginatedQuery, GetPermissionsCustomRolesPaginatedQueryVariables>;
export const GetPermissionsUsersPaginatedDocument = gql`
    query getPermissionsUsersPaginated($search: String, $limit: Int, $sortBy: String, $offset: Int, $sortDirection: String, $userId: String) {
  permissionsUserPaginated(
    search: $search
    limit: $limit
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    userId: $userId
  ) {
    nodes {
      ...PermissionsPaginatedItem
    }
    totalsCount
  }
}
    ${PermissionsPaginatedItemFragmentDoc}`;

/**
 * __useGetPermissionsUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useGetPermissionsUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsUsersPaginatedQuery({
 *   variables: {
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      offset: // value for 'offset'
 *      sortDirection: // value for 'sortDirection'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetPermissionsUsersPaginatedQuery(baseOptions?: Apollo.QueryHookOptions<GetPermissionsUsersPaginatedQuery, GetPermissionsUsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPermissionsUsersPaginatedQuery, GetPermissionsUsersPaginatedQueryVariables>(GetPermissionsUsersPaginatedDocument, options);
      }
export function useGetPermissionsUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPermissionsUsersPaginatedQuery, GetPermissionsUsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPermissionsUsersPaginatedQuery, GetPermissionsUsersPaginatedQueryVariables>(GetPermissionsUsersPaginatedDocument, options);
        }
export type GetPermissionsUsersPaginatedQueryHookResult = ReturnType<typeof useGetPermissionsUsersPaginatedQuery>;
export type GetPermissionsUsersPaginatedLazyQueryHookResult = ReturnType<typeof useGetPermissionsUsersPaginatedLazyQuery>;
export type GetPermissionsUsersPaginatedQueryResult = Apollo.QueryResult<GetPermissionsUsersPaginatedQuery, GetPermissionsUsersPaginatedQueryVariables>;
export const UpdatePermissionUserDocument = gql`
    mutation updatePermissionUser($data: UpdatePermissionsDTO!) {
  updatePermission(data: $data)
}
    `;
export type UpdatePermissionUserMutationFn = Apollo.MutationFunction<UpdatePermissionUserMutation, UpdatePermissionUserMutationVariables>;

/**
 * __useUpdatePermissionUserMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionUserMutation, { data, loading, error }] = useUpdatePermissionUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePermissionUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePermissionUserMutation, UpdatePermissionUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePermissionUserMutation, UpdatePermissionUserMutationVariables>(UpdatePermissionUserDocument, options);
      }
export type UpdatePermissionUserMutationHookResult = ReturnType<typeof useUpdatePermissionUserMutation>;
export type UpdatePermissionUserMutationResult = Apollo.MutationResult<UpdatePermissionUserMutation>;
export type UpdatePermissionUserMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionUserMutation, UpdatePermissionUserMutationVariables>;
export const DeletePermissionUserDocument = gql`
    mutation deletePermissionUser($id: String!) {
  deletePermission(id: $id)
}
    `;
export type DeletePermissionUserMutationFn = Apollo.MutationFunction<DeletePermissionUserMutation, DeletePermissionUserMutationVariables>;

/**
 * __useDeletePermissionUserMutation__
 *
 * To run a mutation, you first call `useDeletePermissionUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionUserMutation, { data, loading, error }] = useDeletePermissionUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePermissionUserMutation(baseOptions?: Apollo.MutationHookOptions<DeletePermissionUserMutation, DeletePermissionUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePermissionUserMutation, DeletePermissionUserMutationVariables>(DeletePermissionUserDocument, options);
      }
export type DeletePermissionUserMutationHookResult = ReturnType<typeof useDeletePermissionUserMutation>;
export type DeletePermissionUserMutationResult = Apollo.MutationResult<DeletePermissionUserMutation>;
export type DeletePermissionUserMutationOptions = Apollo.BaseMutationOptions<DeletePermissionUserMutation, DeletePermissionUserMutationVariables>;
export const UpdatePermissionCustomRoleDocument = gql`
    mutation updatePermissionCustomRole($data: UpdatePermissionsDTO!) {
  updateCustomRolePermission(data: $data)
}
    `;
export type UpdatePermissionCustomRoleMutationFn = Apollo.MutationFunction<UpdatePermissionCustomRoleMutation, UpdatePermissionCustomRoleMutationVariables>;

/**
 * __useUpdatePermissionCustomRoleMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionCustomRoleMutation, { data, loading, error }] = useUpdatePermissionCustomRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePermissionCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePermissionCustomRoleMutation, UpdatePermissionCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePermissionCustomRoleMutation, UpdatePermissionCustomRoleMutationVariables>(UpdatePermissionCustomRoleDocument, options);
      }
export type UpdatePermissionCustomRoleMutationHookResult = ReturnType<typeof useUpdatePermissionCustomRoleMutation>;
export type UpdatePermissionCustomRoleMutationResult = Apollo.MutationResult<UpdatePermissionCustomRoleMutation>;
export type UpdatePermissionCustomRoleMutationOptions = Apollo.BaseMutationOptions<UpdatePermissionCustomRoleMutation, UpdatePermissionCustomRoleMutationVariables>;
export const DeletePermissionsCustomRoleDocument = gql`
    mutation deletePermissionsCustomRole($id: String!) {
  deleteCustomRolePermission(id: $id)
}
    `;
export type DeletePermissionsCustomRoleMutationFn = Apollo.MutationFunction<DeletePermissionsCustomRoleMutation, DeletePermissionsCustomRoleMutationVariables>;

/**
 * __useDeletePermissionsCustomRoleMutation__
 *
 * To run a mutation, you first call `useDeletePermissionsCustomRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePermissionsCustomRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePermissionsCustomRoleMutation, { data, loading, error }] = useDeletePermissionsCustomRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePermissionsCustomRoleMutation(baseOptions?: Apollo.MutationHookOptions<DeletePermissionsCustomRoleMutation, DeletePermissionsCustomRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePermissionsCustomRoleMutation, DeletePermissionsCustomRoleMutationVariables>(DeletePermissionsCustomRoleDocument, options);
      }
export type DeletePermissionsCustomRoleMutationHookResult = ReturnType<typeof useDeletePermissionsCustomRoleMutation>;
export type DeletePermissionsCustomRoleMutationResult = Apollo.MutationResult<DeletePermissionsCustomRoleMutation>;
export type DeletePermissionsCustomRoleMutationOptions = Apollo.BaseMutationOptions<DeletePermissionsCustomRoleMutation, DeletePermissionsCustomRoleMutationVariables>;
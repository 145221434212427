import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";

import RemoteDataTableApi, {
  //   BasicTableActionFunction,
  //   getBasicActionColumns,
  RemoteDataTableApiProps,
} from "../../../shared/components/RemoteDataTableApi";
import {
  GetPermissionsCustomRolesPaginatedDocument,
  PermissionsFragment,
} from "../../../apollo/users/queries.generated";
import {
  GetAllResellerZonesPaginatedDocument,
  ResellerZonesPaginatedItemFragment,
} from "../../../apollo/resellers/queries.generated";
import dayjs from "dayjs";
import Link from "../../../navigation/Link";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import { DocumentNode } from "graphql";
import { GetAllSubResellerZonesPaginatedDocument } from "../../../apollo/subresellers/queries.generated";
import { GetAllCustomerZonesPaginatedDocument } from "../../../apollo/customers/queries.generated";

interface ManageZonesResellerTableProps
  extends Pick<RemoteDataTableApiProps, "onAddClick" | "refresherBoolean"> {
  //   onEditPress?: BasicTableActionFunction<ResellerZonesPaginatedItemFragment>;
  //   onDeletePress?: BasicTableActionFunction<ResellerZonesPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "zoneName",
    headerName: "Zone",
    minWidth: 240,
    // valueGetter: ({ row }) => {
    //   const resellerZones = row as ResellerZonesPaginatedItemFragment;
    //   return resellerZones.zoneName;
    // },
    renderCell: ({ row }) => {
      const zone = row as ResellerZonesPaginatedItemFragment;
      return (
        <Link
          to={"/dashboard/zones/" + zone.zoneName}
        >{`${zone.zoneName}`}</Link>
      );
    },
  },
  {
    flex: 1,
    field: "assignedTo",
    headerName: "Assigned To",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const resellerZones = row as ResellerZonesPaginatedItemFragment;
      return resellerZones.subReseller
        ? resellerZones.subReseller.user.name
        : resellerZones.customer
        ? resellerZones.customer.user.name
        : "-";
    },
  },
  {
    flex: 1,
    field: "AssignedUserRole",
    headerName: "Assigned User Role ",
    minWidth: 100,
    valueGetter: ({ row }) => {
      const resellerZones = row as ResellerZonesPaginatedItemFragment;
      return resellerZones.subReseller
        ? resellerZones.subReseller.user.role
        : resellerZones.customer
        ? resellerZones.customer.user.role
        : "-";
    },
  },
  {
    flex: 1,
    field: "createdAt",
    headerName: "Created At",
    minWidth: 100,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
  {
    flex: 1,
    field: "updatedAt",
    headerName: "Updated At",
    minWidth: 100,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
];

const ManageZonesResellerTable: React.FC<ManageZonesResellerTableProps> = ({
  //   onEditPress,
  //   onDeletePress,
  ...props
}) => {
  const authUser = useAuthUser();

  if (authUser?.role === "SuperAdmin") {
    const resellerColumn = muiColumns.find(
      (column) => column.field === "resellerName"
    );

    if (!resellerColumn) {
      muiColumns.splice(1, 0, {
        flex: 1,
        field: "resellerName",
        headerName: "Reseller",
        minWidth: 200,
        valueGetter: ({ row }) => {
          const resellerZones = row as ResellerZonesPaginatedItemFragment;
          return resellerZones.reseller.user.name;
        },
      });
    }
  }

  return (
    <RemoteDataTableApi
      query={GetAllResellerZonesPaginatedDocument}
      variables={
        authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id }
      }
      //   searchable
      renderAddButton={authUser?.role === "SuperAdmin" ? true : false}
      columns={muiColumns}
      //   actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default ManageZonesResellerTable;

import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";
import {
  GetSubResellersPaginatedDocument,
  SubResellersPaginatedItemFragment,
} from "../../../apollo/subresellers/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";

interface SubResellersTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<SubResellersPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<SubResellersPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },

  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const subReseller = row as SubResellersPaginatedItemFragment;
      return (
        <Link
          to={"/dashboard/subreseller/" + subReseller.id}
        >{`${subReseller.user.name}`}</Link>
      );
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const subReseller = row as SubResellersPaginatedItemFragment;
      return subReseller.user.email;
    },
  },
  {
    flex: 1,
    field: "zonesCount",
    headerName: "Total Zones",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const subReseller = row as SubResellersPaginatedItemFragment;
      return subReseller.zones?.length;
    },
  },
];

const SubResellersTable: React.FC<SubResellersTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  const authUser = useAuthUser();

  if (authUser?.role === "SuperAdmin") {
    const resellerColumn = muiColumns.find(
      (column) => column.field === "reseller"
    );

    if (!resellerColumn)
      muiColumns.push({
        flex: 1,
        field: "reseller",
        headerName: "Reseller",
        minWidth: 200,
        valueGetter: ({ row }) => {
          const subReseller = row as SubResellersPaginatedItemFragment;
          return subReseller.reseller?.user.name;
        },
      });
  }

  return (
    <RemoteDataTable
      query={GetSubResellersPaginatedDocument}
      variables={
        authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id }
      }
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default SubResellersTable;

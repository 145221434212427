import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import { UsersPaginatedItemFragment } from "../../../apollo/users/queries.generated";

import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetAllResellerZonesPaginatedDocument,
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
  ResellerZonesPaginatedItemFragment,
  useAddZoneToResellerMutation,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateResellerZoneDto,
  ResellerZoneDto,
} from "../../../apollo/types.generated";
import { useQuery } from "@apollo/client";
import { GetZoneByNameDocument } from "../../../apollo/zones/queries.generated";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

interface AssignZoneModalProps {
  open: boolean;
  onClose: () => void;
  editingZone?: ResellerZoneDto;
}
interface FormInputs extends CreateResellerZoneDto {}

const getSchema = (editingZone?: ResellerZonesPaginatedItemFragment) =>
  Yup.object({
    resellerId: Yup.string().required("This field is required"),
    zoneId: Yup.string().required("This field is required"),
  }).required();

const getDefaultValues = (
  resellerZone?: ResellerZonesPaginatedItemFragment
): FormInputs | undefined => {
  return {
    resellerId: resellerZone?.resellerId || "",
    zoneId: resellerZone?.zoneName || "",
  };
};
const ResellerAssignZoneFormModal: React.FC<AssignZoneModalProps> = ({
  open,
  onClose,
  editingZone,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema(editingZone)),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingZone),
    }
  );
  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useAddZoneToResellerMutation({
    refetchQueries: [GetAllResellerZonesPaginatedDocument],
  });
  const [zoneField, setZoneField] = React.useState("");
  const { data: getZoneData, loading: getZoneLoading } = useQuery(
    GetZoneByNameDocument,
    {
      variables: {
        zoneName: zoneField,
      },
    }
  );
  const zoneIdField = watch("zoneId");

  //   const [
  //     update,
  //     { data: updateData, loading: updateLoading, error: updateError },
  //   ] = useUpdateParentMutation({
  //     refetchQueries: [GetParentsPaginatedDocument],
  //   });

  const onSubmit = (data: FormInputs) => {
    const { ...rest } = data;
    const dataToSubmit = { ...rest };
    // if (editingSubReseller) {
    //   update({
    //     variables: {
    //       id: editingSubReseller.id,
    //       data: dataToSubmit,
    //     },
    //   });
    //   return;
    // }
    create({
      variables: {
        data: {
          ...dataToSubmit,
        },
      },
    });
  };

  useEffect(() => {
    if (createData) {
      toast.success("Zone added successfully");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error adding Zone: " + createError.message);
    }
  }, [createData, createError]);

  //   useEffect(() => {
  //     if (updateData) {
  //       toast.success("Role updated successfully");
  //       onClose();
  //     }

  //     if (updateError) {
  //       toast.error("Error updating Role: " + updateError.message);
  //     }
  //   }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingZone));
  }, [editingZone]);

  useDidMountEffect(() => {
    if (getZoneData) {
      setValue("zoneId", getZoneData.getZoneByName.id);
    }
  }, [getZoneData]);

  // useEffect(() => {
  //   if (zoneIdField) {
  //     setZoneField(zoneIdField);
  //   }
  // }, [zoneIdField]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingZone ? "Update Assigned" : "Assign"} Zone
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={11}>
              <ControlledSelectModalField
                name="resellerId"
                control={control}
                // variables={
                //   authUser?.role === "reseller"
                //     ? { schoolSystemId: authUser.schoolSystemId }
                //     : undefined
                // }
                initialValue={
                  editingZone?.reseller as ResellersPaginatedItemFragment
                }
                textFieldProps={{
                  label: "Assign Zone to",
                  fullWidth: true,
                }}
                query={GetResellersPaginatedDocument}
                labelsExractor={(item) => ({
                  primary: item.user.name || "",
                  secondary: item.user.role || "",
                })}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems={"center"}>
                <Grid item xs={11}>
                  {/* <ControlledTextField
                    name="zoneId"
                    control={control}
                    label="Zone Name"
                  /> */}
                  <TextField
                    label="Zone Name"
                    fullWidth
                    value={zoneField}
                    onChange={(e) => {
                      setZoneField(e.target.value);
                    }}
                    error={!!zoneField && !getZoneData?.getZoneByName?.name}
                    helperText={
                      !!zoneField && !getZoneData?.getZoneByName?.name
                        ? "Zone does not exist"
                        : ""
                    }
                  />
                </Grid>
                <Grid
                  item
                  // xs={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginLeft: "5px",
                  }}
                >
                  {zoneField &&
                    (getZoneData?.getZoneByName?.name ? (
                      <CheckCircleIcon sx={{ color: "green" }} />
                    ) : (
                      <CancelIcon sx={{ color: "red" }} />
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}></Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton
            loading={
              createLoading
              // || updateLoading
            }
            type="submit"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ResellerAssignZoneFormModal;

import { Ability, AbilityBuilder, AbilityClass } from "@casl/ability";
import createStore from "zustand";
import { AuthUserFragment } from "../../apollo/auth/queries.generated";
import { AppClientType } from "../../apollo/client";

export type Action =
  | "manage"
  | "create"
  | "read"
  | "update"
  | "delete"
  | "toggle";
export type Subjects =
  | "Resellers"
  | "Sub Resellers"
  | "Customers"
  | "Roles"
  | "Permissions"
  | "Employees"
  | "DNS"
  | "Zones"
  | "Users"
  | "all"
  | "Admin";
export type AppAbility = Ability<[Action, Subjects]>;

export type AuthStore = {
  user: AuthUserFragment | null;
  ability: AppAbility;
  isLoading: boolean;
  authError: null | Error;
  authToken: string | null;
  loadUser: (user: AuthUserFragment) => void;
  toggleAdminPermissions: () => void;
  clearUser: (client: AppClientType) => void;
  updateAuthToken: (token: string | null) => void;
  setAuthError: (error: null | Error) => void;
};

const useAuthStore = createStore<AuthStore>((set, get) => ({
  authToken: localStorage.getItem("token"),
  isLoading: true,
  authError: null,
  isAsSupervisor: false,
  user: null,
  ability: new Ability<[Action, Subjects]>(),
  toggleAdminPermissions: () => {},
  clearUser: (client: AppClientType) => {
    if (get().user) {
      client.clearStore();
      set({
        ability: new Ability<[Action, Subjects]>(),
        user: null,
        authToken: null,
      });
      localStorage.removeItem("token");
    }
  },
  loadUser: (user) => {
    const ability = createPermissionsForUser(user);
    set({ ability, user, isLoading: false, authError: null });
  },
  updateAuthToken: (token) => {
    set({ authToken: token });
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  },
  setAuthError: (error) => {
    console.log({ error });
    set({ authError: error, isLoading: false, authToken: null });
  },
}));

const createPermissionsForUser = (user: AuthUserFragment) => {
  const { can, build } = new AbilityBuilder<Ability<[Action, Subjects]>>(
    Ability as AbilityClass<AppAbility>
  );

  if (user.role === "SuperAdmin") {
    can("manage", "all"); // read-write access to everything
  }

  if (user.role === "Reseller") {
    can("manage", "Sub Resellers");
    can("manage", "Customers");
    can("manage", "Permissions");
    can("manage", "Users");
    can("read", "DNS");
    can("read", "Roles");
  }

  if (user.role === "SubReseller") {
    can("manage", "Customers");
    can("manage", "Permissions");
    can("manage", "Users");
    can("read", "DNS");
    can("read", "Roles");
  }

  if (user.role === "Customer") {
    can("manage", "Permissions");
    can("manage", "Users");
    can("read", "DNS");
    can("read", "Roles");
  }

  // if (user.role === "SchoolSystemManager") {
  //   can("read", "School Systems");
  //   can("update", "School Systems");
  //   can("manage", "Schools");
  //   can("manage", "Students");
  //   can("manage", "Parents");
  //   can("manage", "Bus Drivers");
  //   can("manage", "Bus Routes");
  //   can("manage", "Users");
  //   can("manage", "Families");
  //   can("manage", "Vacation Periods");
  // }

  // if (user.role === "SchoolManager") {
  //   can("read", "School Systems");
  //   // can("read", "Schools");
  //   can("manage", "Students");
  //   can("manage", "Parents");
  //   can("manage", "Bus Drivers");
  //   can("manage", "Bus Routes");
  //   can("manage", "Bus Routes");
  //   can("manage", "Users");
  // }

  // if (user.role === "Parent") {
  //   can("manage", "My Subscription");
  // }

  return build();
};

export default useAuthStore;

import { GridColDef } from "@mui/x-data-grid";
import React from "react";
import RemoteDataTableApi from "../../../shared/components/RemoteDataTableApi";
import dayjs from "dayjs";
import {
  GetZoneListPaginatedDocument,
  ZoneFragment,
} from "../../../apollo/zones/queries.generated";
import Link from "../../../navigation/Link";

interface ZonesTableProps {}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Zone name",
    minWidth: 200,
    sortable: false,
    renderCell: ({ row }) => {
      const zone = row as ZoneFragment;
      return <Link to={"/dashboard/zones/" + zone.name}>{`${zone.name}`}</Link>;
    },
  },
  {
    flex: 1,
    field: "status",
    headerName: "Status",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "type",
    headerName: "Type",
    minWidth: 50,
    sortable: false,
  },
  {
    flex: 1,
    field: "nameservers",
    headerName: "Nameservers",
    minWidth: 200,
    sortable: false,
    valueGetter: ({ value }) => {
      const nameservers = value as string[];
      return nameservers.join(" ; ");
    },
  },
  {
    flex: 1,
    field: "originalNameservers",
    headerName: "Original Nameservers",
    minWidth: 200,
    sortable: false,
    valueGetter: ({ value }) => {
      const nameservers = value as string[];
      return nameservers ? nameservers.join(" ; ") : "None";
    },
  },
  {
    flex: 1,
    field: "activatedOn",
    headerName: "Activated At",
    minWidth: 100,
    sortable: false,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
  {
    flex: 1,
    field: "createdOn",
    headerName: "Created At",
    minWidth: 100,
    sortable: false,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
  {
    flex: 1,
    field: "updatedOn",
    headerName: "Updated At",
    minWidth: 100,
    sortable: false,
    valueGetter: ({ value }) => {
      const date = value as Date;
      return dayjs(date).format("YYYY-MM-DD");
    },
  },
];

const ZonesTable: React.FC<ZonesTableProps> = ({ ...props }) => {
  return (
    <RemoteDataTableApi
      query={GetZoneListPaginatedDocument}
      variables={{ limit: 10, page: 1 }}
      columns={muiColumns}
      //   actionColumns={getBasicActionColumns()}
      {...props}
    />
  );
};

export default ZonesTable;

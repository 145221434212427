import { GridColDef } from "@mui/x-data-grid";
import React, { useEffect } from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";
import {
  GetUsersPaginatedDocument,
  UsersLinkedItemFragment,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import { DocumentNode } from "graphql";
import {
  GetResellerUsersDocument,
  useGetResellerByUserIdLazyQuery,
} from "../../../apollo/resellers/queries.generated";
import {
  GetSubResellerUsersDocument,
  useGetSubResellerByUserIdLazyQuery,
} from "../../../apollo/subresellers/queries.generated";
import {
  GetCustomerUsersDocument,
  useCustomerByUserIdLazyQuery,
} from "../../../apollo/customers/queries.generated";

interface UsersTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<UsersPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<UsersPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const user = row as UsersLinkedItemFragment;
      return (
        <Link to={"/dashboard/user/" + user.id}>{`${user.user?.name}`}</Link>
      );
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersLinkedItemFragment;
      return user.user.email;
    },
  },
  {
    flex: 1,
    field: "role",
    headerName: "Main Role",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersLinkedItemFragment;
      return user.role;
    },
  },
  {
    flex: 1,
    field: "customRole",
    headerName: "Custom Role",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersLinkedItemFragment;
      return user.user.customRole?.customRoles?.name || "None";
    },
  },
  {
    flex: 1,
    field: "permissions",
    headerName: "Total Permissions",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const user = row as UsersLinkedItemFragment;
      return user.user.permissions?.length || 0;
    },
  },
];

const UsersTable: React.FC<UsersTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  const authUser = useAuthUser();

  const [usersQuery, setusersQuery] = React.useState<DocumentNode>(
    GetUsersPaginatedDocument
  );
  const [userRoleId, setUserRoleId] = React.useState<string>("");
  const [getReseller, { data: resellerData }] =
    useGetResellerByUserIdLazyQuery();
  const [getSubReseller, { data: subResellerData }] =
    useGetSubResellerByUserIdLazyQuery();
  const [getCustomer, { data: customerData }] = useCustomerByUserIdLazyQuery();

  useEffect(() => {
    if (authUser) {
      if (authUser?.role === "Reseller") {
        setusersQuery(GetResellerUsersDocument);

        getReseller({
          variables: {
            id: authUser?.id,
          },
        });
      }
      if (authUser?.role === "SubReseller") {
        setusersQuery(GetSubResellerUsersDocument);

        getSubReseller({
          variables: {
            id: authUser?.id,
          },
        });
      }
      if (authUser?.role === "Customer") {
        setusersQuery(GetCustomerUsersDocument);

        getCustomer({
          variables: {
            userId: authUser?.id,
          },
        });
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (resellerData) {
      setUserRoleId(resellerData.getReseller?.id);
    }
  }, [resellerData]);

  useEffect(() => {
    if (subResellerData) {
      setUserRoleId(subResellerData.getSubReseller?.id || "");
    }
  }, [subResellerData]);

  useEffect(() => {
    if (customerData) {
      setUserRoleId(customerData.customerByUserId?.id || "");
    }
  }, [customerData]);

  return (
    <RemoteDataTable
      query={usersQuery}
      variables={{ userRoleId: userRoleId }}
      searchable
      // renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default UsersTable;

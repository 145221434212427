import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useParams } from "react-router-dom";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import useAuthUser from "../../shared/hooks/useAuthUser";
import DNSTable from "./components/DNSTable";
import WarningIcon from "@mui/icons-material/Warning";
import ZoneDetails from "./components/ZoneDetails";

interface ZoneInfoPageProps {}

const ZoneInfoPage: React.FC<ZoneInfoPageProps> = ({}) => {
  const authUser = useAuthUser();
  let { zoneName } = useParams<{ zoneName: string }>();

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          // alignItems: "center",
          height: "100vh",
        }}
      >
        {authUser?.zones?.includes(zoneName) ||
        authUser?.role === "SuperAdmin" ? (
          <Grid container justifyItems={"center"}>
            <Grid item xs={12}>
              <ZoneDetails zoneName={zoneName} />
            </Grid>
            <Grid item xs={11}>
              <Typography variant="h2">DNS</Typography>
              <DNSTable zoneName={zoneName} />
            </Grid>
          </Grid>
        ) : (
          <Typography variant="h2" marginTop={"20px"}>
            Error
            <br />
            <WarningIcon sx={{ fontSize: 50 }} color={"warning"} />
            {"  You do not have access to this zone  "}
            <WarningIcon sx={{ fontSize: 50 }} color={"warning"} />
          </Typography>
        )}
      </Box>
    </PageContainer>
  );
};

export default ZoneInfoPage;

import { Paper, Grid, Typography, TextField, Button, Box } from "@mui/material";

import React, { useEffect } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import LoadingBackdrop from "../../shared/components/LoadingBackdrop";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Redirect } from "react-router";
import Link from "../../navigation/Link";
import { useAuthenticateLazyQuery } from "../../apollo/auth/queries.generated";
import useAuthStore from "../../shared/stores/authStore";
interface SignInPageProps {}

type Inputs = {
  email: string;
  password: string;
};

const schema: Yup.SchemaOf<Inputs> = Yup.object({
  email: Yup.string().required("This field is required.").defined(),
  password: Yup.string().required("This field is required.").defined(),
}).defined();

const SignInPage: React.FC<SignInPageProps> = ({}) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
  });
  const [authenticate, { data, loading, error }] = useAuthenticateLazyQuery();
  const { loadUser, updateAuthToken, authToken, setAuthError } = useAuthStore();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    authenticate({ variables: data });
  };

  useEffect(() => {
    if (data) {
      loadUser(data.authenticate.user);
      updateAuthToken(data.authenticate.token);
    }

    if (error) {
      setAuthError(error);
    }
  }, [data, error]);

  if (authToken) {
    return <Redirect to="/dashboard" />;
  }

  return (
    // <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
    <Grid container alignContent={"center"} sx={{ flex: 1, padding: "5%" }}>
      {/* <LoadingBackdrop open={loading} /> */}
      {/* <Grid container alignItems="center" sx={{ flex: 1, padding: "5%" }}> */}
      {/* <Grid
          item
          md={12}
          sx={{
            position: "relative",
            display: {
              xs: "none",
              sm: "none",
              md: "block",
            },
          }}
        > */}
      {/* <Typography variant="h2">
            Welcome to{" "}
            <Typography fontWeight="bold" variant="inherit" color="primary">
              Arttiee
            </Typography> */}
      {/* </Typography> */}
      {/* </Grid> */}
      <Grid item xs={6} md={12}>
        <LoadingBackdrop open={loading} />
        <Paper
          variant="outlined"
          sx={{
            height: {
              xs: "65vh",
              md: "80vh",
            },

            padding: "10%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* <Typography
              component="p"
              textAlign="start"
              sx={{
                fontSize: {
                  xs: "1.5rem",
                  md: "2.125rem",
                },
                marginBottom: "20px",
                display: {
                  xs: "block",
                  md: "none",
                },
              }}
            >
              Welcome to{" "}
              <Typography
                fontWeight="bold"
                variant="inherit"
                component={"span"}
                color="primary"
              >
                Arttiee
              </Typography>
            </Typography> */}
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            alignContent="center"
          >
            <Grid container alignItems="start" justifyContent="start" sx={{}}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  textAlign="start"
                  sx={{
                    marginBottom: "30px",
                    fontSize: {
                      xs: "1.3rem",
                      md: "2.125rem",
                    },
                  }}
                >
                  Sign In
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="body1" color="red">
                  {error?.message}
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Email"
                        fullWidth
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        sx={{ marginBottom: "50px" }}
                      />
                    )}
                  />

                  <Controller
                    defaultValue=""
                    name="password"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password"
                        fullWidth
                        type="password"
                        error={!!errors.password}
                        helperText={errors.password?.message}
                        sx={{ marginBottom: "50px" }}
                      />
                    )}
                  />

                  <Button
                    variant="outlined"
                    color="success"
                    type="submit"
                    size="large"
                    fullWidth
                  >
                    Sign In
                  </Button>
                </form>
              </Grid>
              {/* <Grid item xs={12}>
                  <Typography sx={{ textAlign: "start", marginTop: "20px" }}>
                    Not registered yet?
                    <Link to="/signUp"> Create an account.</Link>
                  </Typography>
                </Grid> */}
            </Grid>
          </Box>
        </Paper>
        {/* </Grid> */}
      </Grid>
    </Grid>
    // </Box>
  );
};

export default SignInPage;

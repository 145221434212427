import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
} from "../../apollo/resellers/queries.generated";
import {
  CustomRoleFragment,
  DeleteCustomRoleDocument,
  GetUserCustomRolesPaginatedDocument,
} from "../../apollo/users/queries.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import AssignCustomRoleModal from "./components/AssignCustomRoleModal";
import MyRolesTable from "./components/MyRolesTable";
import RolesFormModal from "./components/RolesFormModal";

interface MyRolesPageProps {}

const MyRolesPage: React.FC<MyRolesPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<CustomRoleFragment>();

  const [showAssignModal, setShowAssignModal] = useState(false);
  const handleAssignModalClose = () => {
    setShowAssignModal(false);
  };

  const handleFormClose = () => {
    setSelectedRole(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedRole(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <RolesFormModal
        editingRole={selectedRole}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <AssignCustomRoleModal
        open={showAssignModal}
        onClose={handleAssignModalClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteCustomRoleDocument}
        updateQueries={[GetUserCustomRolesPaginatedDocument]}
        variables={{ id: selectedRole?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Reseller"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Custom Roles
        </Typography>
        <Grid container spacing={2} marginTop={"10px"}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setShowAssignModal(true)}
          >
            Assign Role
          </Button>
        </Grid>
        <MyRolesTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedRole(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedRole(item);
            setShowDeleteModal(true);
          }}
        />
      </TableCard>
    </PageContainer>
  );
};

export default MyRolesPage;

import React, { useMemo, useReducer } from "react";
import { Button, Grid } from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridSortDirection,
  GridSortModel,
} from "@mui/x-data-grid";
import { DocumentNode } from "graphql";
import { useQuery } from "@apollo/client";
import SearchField from "./SearchField";
import useDebounce from "../hooks/useDebounce";
import useDidMountEffect from "../hooks/useDidMountEffect";
import { FiPlus } from "react-icons/fi";

// type SingleSortModel = {
//   field: string;
//   sort: GridSortDirection;
// };

type RemoteDataTableState = {
  //   search: string;
  //   sortModel: SingleSortModel;
  limit: number;
  currentPage: number;
};

type Action =
  //   | { type: "searchChanged"; search: string }
  //   | { type: "sortModelChanged"; model: SingleSortModel }
  | { type: "currentPageChanged"; page: number }
  | { type: "limitChanged"; limit: number };

const reducer = (
  state: RemoteDataTableState,
  action: Action
): RemoteDataTableState => {
  switch (action.type) {
    // case "searchChanged":
    //   return {
    //     ...state,
    //     search: action.search,
    //     currentPage: 0,
    //   };

    case "currentPageChanged":
      return {
        ...state,
        currentPage: action.page + 1,
      };

    case "limitChanged":
      return { ...state, limit: action.limit };

    // case "sortModelChanged":
    //   return { ...state, sortModel: action.model };
    default:
      return state;
  }
};

const initialState: RemoteDataTableState = {
  //   search: "",
  //   sortModel: { field: "createdAt", sort: "desc" },
  limit: 10,
  currentPage: 0,
};

export interface RemoteDataTableApiProps {
  query: DocumentNode;
  columns: GridColumns;
  actionColumns?: GridColumns;
  variables?: any;
  //   searchable?: boolean;
  refresherBoolean?: boolean;
  extraComponent?: React.ReactNode;
  renderAddButton?: boolean;
  disableAddButton?: boolean;
  onAddClick?: () => void;
}

const RemoteDataTableApi = ({
  query,
  columns,
  variables,
  //   searchable = true,
  refresherBoolean,
  renderAddButton,
  disableAddButton,
  onAddClick,
}: RemoteDataTableApiProps) => {
  const [{ currentPage, limit }, dispatch] = useReducer(reducer, initialState);

  const { data, refetch, loading } = useQuery(query, { variables });

  const { nodes, total } = useMemo(() => {
    if (!data) return { nodes: [] as any[], total: 0 };
    const resultKey = getFirstKey(data);
    if (!resultKey) return { nodes: [] as any[], total: 0 };
    const possibleNodes: any[] = data[resultKey].nodes;
    return { nodes: possibleNodes, total: data[resultKey].totalsCount };
  }, [data]);

  const onLimitChange = (limit: number) => {
    dispatch({
      type: "limitChanged",
      limit,
    });
  };

  const onPageChange = (page: number) => {
    dispatch({
      type: "currentPageChanged",
      page,
    });
  };

  useDidMountEffect(() => {
    refetch({
      ...variables,
      limit: limit,
      //   offset: limit * currentPage,
      page: currentPage,
    });
  }, [currentPage, limit, refresherBoolean]);

  return (
    <Grid container justifyContent="flex-end" spacing={2} flex={1}>
      {/* {searchable && (
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SearchField
            onChange={(e) => {
              dispatch({ search: e.target.value, type: "searchChanged" });
            }}
          />
        </Grid>
      )} */}
      {renderAddButton && (
        <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
          <Button
            disableElevation
            disabled={disableAddButton}
            variant="contained"
            color="primary"
            fullWidth
            onClick={onAddClick}
            startIcon={<FiPlus />}
          >
            Add New
          </Button>
        </Grid>
      )}

      <Grid item xs={12} height="80%" maxHeight={"800px"}>
        <DataGrid
          style={{
            border: "none",
          }}
          rowCount={total}
          loading={loading}
          //   onSortModelChange={handleOnSortChange}
          columns={columns}
          pageSize={limit}
          onPageSizeChange={onLimitChange}
          rowsPerPageOptions={[10, 20, 50]}
          onPageChange={onPageChange}
          paginationMode="server"
          sortingMode="server"
          rows={nodes || []}
          autoHeight
        />
      </Grid>
    </Grid>
  );
};

export interface PaginationArgs {
  //   sortDirection?: "asc" | "desc";

  //   sortBy?: string;

  limit?: number;

  offset?: number;

  //   search?: string;
}

const getFirstKey = (object: object) => {
  for (let key in object) {
    return key;
  }
};

export default RemoteDataTableApi;

import { useApolloClient } from "@apollo/client";
import { Close, Settings } from "@mui/icons-material";
import Menu from "@mui/icons-material/Menu";
import {
  AppBar,
  colors,
  Divider,
  Fade,
  Grid,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { AppClientType } from "../apollo/client";
import useAbility from "../shared/hooks/useAbility";
import useAuthStore from "../shared/stores/authStore";

interface NavBar {
  isOpen: boolean;
  onMenuClick?: () => void;
}

const NavBar: React.FC<NavBar> = ({ onMenuClick, isOpen }) => {
  const { user, clearUser } = useAuthStore(({ user, clearUser }) => ({
    user,
    clearUser,
  }));
  const client = useApolloClient() as AppClientType;
  const clearAuthUser = useCallback(() => clearUser(client), [client]);
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const ability = useAbility();
  // const renderSettingsModal =
  //   ability.cannot("manage", "School Systems") &&
  //   ability.can("manage", "Schools");

  const handleSettingsModalClose = useCallback(() => {
    setShowSettingsModal(false);
  }, []);

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "transparent",
        boxShadow: "none",
        marginBottom: "50px",
      }}
    >
      {/* {renderSettingsModal && (
        <SchoolSystemSettingsModal
          open={showSettingsModal}
          // schoolSystemId="2bd76cd3-2b5d-4a0c-8529-718ddb9ed766"
          onClose={handleSettingsModalClose}
        />
      )} */}
      <Toolbar
        sx={{
          height: "100px",
        }}
      >
        <Grid container alignItems="stretch" justifyContent="space-between">
          <Grid item xs={1}>
            <Fade in={!isOpen}>
              <IconButton
                size="large"
                edge="start"
                color="primary"
                aria-label="open drawer"
                sx={{
                  mr: 2,
                  display: {
                    lg: "none",
                  },
                }}
                onClick={onMenuClick}
              >
                <Menu />
              </IconButton>
            </Fade>
          </Grid>
          {/* {(!!user?.schoolSystem || !!user?.school) && (
            <Grid
              item
              container
              alignItems="flex-start"
              xs
              justifyContent="center"
              direction="column"
              // xs={8}
              // sm={6}
            >
              {!!user?.school && (
                <Typography
                  color={colors.grey["500"]}
                  fontWeight="bold"
                >{` ${user?.school?.name}`}</Typography>
              )}
              <Typography
                color={colors.grey["500"]}
              >{` ${user?.schoolSystem?.name}`}</Typography>
            </Grid>
          )} */}

          <Grid item lg={3} sm={4} xs={8} container alignItems="center">
            <Typography color={colors.grey["500"]}>
              Welcome,
              <Typography
                fontWeight="bold"
                variant="inherit"
                component="span"
                display="inline"
                color={colors.grey["500"]}
              >
                {` ${user?.firstName} ${user?.lastName}`}
              </Typography>
            </Typography>
            {/* {renderSettingsModal && (
              <Tooltip title="School System settings">
                <IconButton onClick={() => setShowSettingsModal(true)}>
                  <Settings />
                </IconButton>
              </Tooltip>
            )} */}
            <Tooltip title="Sign out">
              <IconButton onClick={clearAuthUser}>
                <FaSignOutAlt />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default NavBar;

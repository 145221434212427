import {
  Box,
  Button,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useRadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ResellerZonesPaginatedItemFragment } from "../../apollo/resellers/queries.generated";
import { PermissionsFragment } from "../../apollo/users/queries.generated";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import ResellerAssignZoneFormModal from "./components/ResellerAssignZoneFormModal";
import ManageZonesResellerTable from "./components/ManageZonesResellerTable";
import UsersAssignZoneFormModal from "./components/UsersAssignZoneForm";
import useAuthUser from "../../shared/hooks/useAuthUser";
import ManageZonesSubResellerTable from "./components/ManageZonesSubResellerTable";
import ManageZonesCustomerTable from "./components/ManageZonesCustomerTable";

interface ManageZonesPageProps {}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledRadioButtonsControlLabel = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
)(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

const ManageZonesPage: React.FC<ManageZonesPageProps> = ({}) => {
  const authUser = useAuthUser();
  const [showFormModal, setShowFormModal] = useState(false);
  const [showAssignZoneModal, setShowAssignZoneModal] = useState(false);
  const [option, setOption] = React.useState<String>("reseller");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedResellerZone, setSelectedResellerZone] =
    useState<ResellerZonesPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedResellerZone(undefined);
    setShowFormModal(false);
  };

  const handleAssignZoneClose = () => {
    setShowAssignZoneModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedResellerZone(undefined);
    setShowDeleteModal(false);
  };

  function UserTypeControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    useEffect(() => {
      if (radioGroup) {
        setOption(radioGroup.value);
      }
    }, [radioGroup?.value]);

    return <StyledRadioButtonsControlLabel checked={checked} {...props} />;
  }

  useEffect(() => {
    if (authUser?.role === "Reseller") {
      setOption("reseller");
    }

    if (authUser?.role === "SubReseller") {
      setOption("subReseller");
    }

    if (authUser?.role === "Customer") {
      setOption("customer");
    }
  }, [authUser]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <ResellerAssignZoneFormModal
        // selectedResellerZone={selectedResellerZone}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <UsersAssignZoneFormModal
        // selectedResellerZone={selectedResellerZone}
        open={showAssignZoneModal}
        onClose={handleAssignZoneClose}
      />
      {/* <DeleteModal
        open={showDeleteModal}
        // mutation={DeleteResellerDocument}
        updateQueries={[GetResellersPaginatedDocument]}
        variables={{ id: selectedReseller?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Reseller"
      /> */}
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Manage Zones
        </Typography>
        <Grid container spacing={2} marginTop={"10px"} marginBottom={"10px"}>
          <Button
            onClick={() => setShowAssignZoneModal(true)}
            variant="contained"
            color="primary"
          >
            Assign a Zone
          </Button>
        </Grid>

        {authUser?.role === "SuperAdmin" && (
          <Grid item xs={4}>
            <Typography variant="inherit" textAlign="start">
              Choose an option
            </Typography>
            <RadioGroup row name="options" defaultValue="reseller">
              <UserTypeControlLabel
                value="reseller"
                label="Resellers Zones"
                control={<Radio />}
              />
              <UserTypeControlLabel
                value="subReseller"
                label="Sub Resellers Zones"
                control={<Radio />}
              />
              <UserTypeControlLabel
                value="customer"
                label="Customers Zones"
                control={<Radio />}
              />
            </RadioGroup>
          </Grid>
        )}

        {option === "reseller" && (
          <ManageZonesResellerTable
            onAddClick={() => setShowFormModal(true)}
            //   onEditPress={(item) => {
            //     setSelectedResellerZone(item);
            //     setShowFormModal(true);
            //   }}
            //   onDeletePress={(item) => {
            //     setSelectedResellerZone(item);
            //     setShowDeleteModal(true);
            //   }}
          />
        )}

        {option === "subReseller" && (
          <ManageZonesSubResellerTable
            onAddClick={() => setShowFormModal(true)}
            //   onEditPress={(item) => {
            //     setSelectedResellerZone(item);
            //     setShowFormModal(true);
            //   }}
            //   onDeletePress={(item) => {
            //     setSelectedResellerZone(item);
            //     setShowDeleteModal(true);
            //   }}
          />
        )}

        {option === "customer" && (
          <ManageZonesCustomerTable
            onAddClick={() => setShowFormModal(true)}
            //   onEditPress={(item) => {
            //     setSelectedResellerZone(item);
            //     setShowFormModal(true);
            //   }}
            //   onDeletePress={(item) => {
            //     setSelectedResellerZone(item);
            //     setShowDeleteModal(true);
            //   }}
          />
        )}
      </TableCard>
    </Box>
  );
};

export default ManageZonesPage;

import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  CustomerFragment,
  CustomersPaginatedDocument,
} from "../../apollo/customers/queries.generated";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
} from "../../apollo/resellers/queries.generated";
import { DeleteUserDocument } from "../../apollo/users/queries.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import CustomerFormModal from "./components/CustomerFormModal";
import CustomersTable from "./components/CustomersTable";

interface CustomersListPageProps {}

const CustomersListPage: React.FC<CustomersListPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<CustomerFragment>();

  const handleFormClose = () => {
    setSelectedCustomer(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedCustomer(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <CustomerFormModal
        editingCustomer={selectedCustomer}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteUserDocument}
        updateQueries={[CustomersPaginatedDocument]}
        variables={{ id: selectedCustomer?.user?.id }}
        onClose={handleDeleteModalClose}
        title="Delete Reseller"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Customers
        </Typography>
        <CustomersTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedCustomer(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedCustomer(item);
            setShowDeleteModal(true);
          }}
        />
      </TableCard>
    </PageContainer>
  );
};

export default CustomersListPage;

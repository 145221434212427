import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
} from "../../apollo/resellers/queries.generated";
import {
  GetSubResellersPaginatedDocument,
  SubResellersPaginatedItemFragment,
} from "../../apollo/subresellers/queries.generated";
import { DeleteUserDocument } from "../../apollo/users/queries.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import SubResellerFormModal from "./components/SubResellersFormModal";
import SubResellersTable from "./components/SubResellersTable";
interface SubResellersListPageProps {}

const SubResellersListPage: React.FC<SubResellersListPageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedSubReseller, setSelectedSubReseller] =
    useState<SubResellersPaginatedItemFragment>();

  const handleFormClose = () => {
    setSelectedSubReseller(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedSubReseller(undefined);
    setShowDeleteModal(false);
  };
  return (
    <PageContainer>
      <SubResellerFormModal
        editingSubReseller={selectedSubReseller}
        open={showFormModal}
        onClose={handleFormClose}
      />
      <DeleteModal
        open={showDeleteModal}
        mutation={DeleteUserDocument}
        updateQueries={[GetSubResellersPaginatedDocument]}
        variables={{ id: selectedSubReseller?.user?.id }}
        onClose={handleDeleteModalClose}
        title="Delete SubReseller"
      />
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Sub Resellers
        </Typography>
        <SubResellersTable
          onAddClick={() => setShowFormModal(true)}
          onEditPress={(item) => {
            setSelectedSubReseller(item);
            setShowFormModal(true);
          }}
          onDeletePress={(item) => {
            setSelectedSubReseller(item);
            setShowDeleteModal(true);
          }}
        />
      </TableCard>
    </PageContainer>
  );
};

export default SubResellersListPage;

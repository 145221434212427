import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  FormControlLabelProps,
  styled,
  useRadioGroup,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  GetUsersPaginatedDocument,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";

import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetAllResellerZonesPaginatedDocument,
  GetAllResellerZonesPaginatedQuery,
  GetResellersPaginatedDocument,
  GetResellerUsersDocument,
  ResellersPaginatedItemFragment,
  ResellerZonesPaginatedDocument,
  ResellerZonesPaginatedItemFragment,
  useAddZoneToResellerMutation,
  useGetResellerByUserIdLazyQuery,
  useGetResellerUsersLazyQuery,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateCustomerZoneDto,
  CreateResellerZoneDto,
  CreateSubResellerZoneDto,
  ResellerZoneDto,
  UsersLinkedDto,
  UsersLinkedPaginatedDto,
} from "../../../apollo/types.generated";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GetZoneByNameDocument } from "../../../apollo/zones/queries.generated";
import SearchIcon from "@mui/icons-material/Search";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  GetSubResellerUsersDocument,
  SubResellerZonesPaginatedDocument,
  useAddZoneToSubResellerMutation,
  useGetSubResellerByUserIdLazyQuery,
  useGetSubResellerUsersLazyQuery,
} from "../../../apollo/subresellers/queries.generated";
import { useAddZoneToCustomerMutation } from "../../../apollo/customers/queries.generated";

interface UserAssignZoneFormProps {
  open: boolean;
  onClose: () => void;
  editingZone?: UsersLinkedDto;
}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledRadioButtonsControlLabel = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
)(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

interface FormInputs {
  userId: string;
  userRoleId: string;
  resellerZoneId: string;
  subResellerZoneId: string;
  targetUserId: string;
  targetUserRole: string;
  targetUserRoleId: string;
}

const getSchema = () =>
  Yup.object({
    userId: Yup.string().optional(),
    userRoleId: Yup.string().optional(),
    resellerZoneId: Yup.string().optional(),
    subResellerZoneId: Yup.string().optional(),
    targetUserRole: Yup.string().optional(),
    targetUserRoleId: Yup.string().optional(),
    targetUserId: Yup.string().optional(),
  }).required();

const UsersAssignZoneFormModal: React.FC<UserAssignZoneFormProps> = ({
  open,
  onClose,
  editingZone,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, watch } = useForm<FormInputs>(
    {
      resolver: yupResolver(getSchema()),
      shouldFocusError: true,
    }
  );
  const [
    createSubResellerZone,
    {
      data: createSubResellerZoneData,
      loading: createSubResellerZoneLoading,
      error: createSubResellerZoneError,
    },
  ] = useAddZoneToSubResellerMutation({
    refetchQueries: [GetAllResellerZonesPaginatedDocument],
  });

  const [
    createCustomerZone,
    {
      data: createCustomerZoneData,
      loading: createCustomerZoneLoading,
      error: createCustomerZoneError,
    },
  ] = useAddZoneToCustomerMutation({
    refetchQueries: [GetAllResellerZonesPaginatedDocument],
  });

  const [zoneField, setZoneField] = React.useState("");

  const userRoleId = watch("userRoleId");
  const targetUserId = watch("targetUserId");
  const targetUserRoleId = watch("targetUserRoleId");
  const resellerZoneId = watch("resellerZoneId");
  const subResellerZoneId = watch("subResellerZoneId");
  const targetUserRole = watch("targetUserRole");
  const userId = watch("userId");

  const [userRole, setUserRole] = React.useState("");
  const [auxUserId, setAuxUserId] = React.useState("");
  const [auxUserRoleId, setAuxUserRoleId] = React.useState("");
  const [auxTargetUserRole, setAuxTargetUserRole] = React.useState("");

  // const { data: resellerUsersData, refetch: resellerUsersRefetch } = useQuery(
  //   GetResellerUsersDocument,
  //   {
  //     variables: {
  //       resellerId: userRoleId ? userRoleId : "",
  //     },
  //   }
  // );

  const [
    getResellerUsers,
    { data: resellerUsersData, refetch: resellerUsersRefetch },
  ] = useGetResellerUsersLazyQuery({
    variables: {
      userRoleId: userRoleId ? userRoleId : "",
    },
  });

  const [
    getSubResellerUsers,
    { data: subResellerUsersData, refetch: subResellerUsersRefetch },
  ] = useGetSubResellerUsersLazyQuery({
    variables: {
      userRoleId: userRoleId ? userRoleId : "",
    },
  });

  const [getReseller, { data: resellerData }] =
    useGetResellerByUserIdLazyQuery();
  const [getSubReseller, { data: subResellerData }] =
    useGetSubResellerByUserIdLazyQuery();

  //   const [
  //     update,
  //     { data: updateData, loading: updateLoading, error: updateError },
  //   ] = useUpdateParentMutation({
  //     refetchQueries: [GetParentsPaginatedDocument],
  //   });

  const onSubmit = (data: FormInputs) => {
    const { ...rest } = data;
    // if (editingSubReseller) {
    //   update({
    //     variables: {
    //       id: editingSubReseller.id,
    //       data: dataToSubmit,
    //     },
    //   });
    //   return;
    // }

    if (!resellerZoneId && !subResellerZoneId) {
      return toast.error("Error: Please fill all the fields");
    }

    if (
      targetUserRole === "SubReseller" ||
      auxTargetUserRole === "SubReseller"
    ) {
      const dataToSubmit: CreateSubResellerZoneDto = {
        resellerZoneId: rest?.resellerZoneId,
        subResellerId: rest?.targetUserRoleId,
      };

      createSubResellerZone({
        variables: {
          data: {
            ...dataToSubmit,
          },
        },
      });
    }

    if (targetUserRole === "Customer" || auxTargetUserRole === "Customer") {
      const dataToSubmit: CreateCustomerZoneDto = {
        customerId: rest?.targetUserRoleId,
        resellerZoneId: rest?.resellerZoneId ? rest?.resellerZoneId : null,
        subResellerZoneId: rest?.subResellerZoneId
          ? rest?.subResellerZoneId
          : null,
      };

      createCustomerZone({
        variables: {
          data: {
            ...dataToSubmit,
          },
        },
      });
    }

    // create({
    //   variables: {
    //     data: {
    //       ...dataToSubmit,
    //     },
    //   },
    // });
  };

  useEffect(() => {}, [
    userRoleId,
    targetUserId,
    resellerZoneId,
    targetUserRole,
    auxUserId,
  ]);

  useEffect(() => {
    if (createSubResellerZoneData) {
      toast.success("Zone added successfully");
      reset();
      onClose();
    }

    if (createSubResellerZoneError) {
      toast.error("Error adding Zone: " + createSubResellerZoneError.message);
    }
  }, [createSubResellerZoneData, createSubResellerZoneError]);

  useEffect(() => {
    if (createCustomerZoneData) {
      toast.success("Zone added successfully");
      reset();
      onClose();
    }

    if (createCustomerZoneError) {
      toast.error("Error adding Zone: " + createCustomerZoneError.message);
    }
  }, [createCustomerZoneData, createCustomerZoneError]);

  //   useEffect(() => {
  //     if (updateData) {
  //       toast.success("Role updated successfully");
  //       onClose();
  //     }

  //     if (updateError) {
  //       toast.error("Error updating Role: " + updateError.message);
  //     }
  //   }, [updateData, updateError]);

  useDidMountEffect(() => {
    if (targetUserId) {
      if (authUser?.role === "Reseller" || userRole === "Reseller") {
        getResellerUsers()
          .then((res) => {
            res.data?.getResellerUsers?.nodes!.map((data: UsersLinkedDto) => {
              if (data && data.id === targetUserId) {
                setValue("targetUserRoleId", data.roleId);
                setValue("targetUserRole", data.role);
                setAuxUserRoleId(data.roleId);
                setAuxTargetUserRole(data.role);
              }
              return data;
            });
          })
          .catch((err) => {
            toast.error("Error getting Reseller Users: " + err.message);
          });
      }

      if (authUser?.role === "SubReseller" || userRole === "SubReseller") {
        getSubResellerUsers()
          .then((res) => {
            res.data?.getSubResellerUsers?.nodes!.map(
              (data: UsersLinkedDto) => {
                if (data && data.id === targetUserId) {
                  setValue("targetUserRoleId", data.roleId);
                  setValue("targetUserRole", data.role);
                  setAuxUserRoleId(data.roleId);
                  setAuxTargetUserRole(data.role);
                }
                return data;
              }
            );
          })
          .catch((err) => {
            toast.error("Error getting SubReseller Users: " + err.message);
          });
      }
    }
  }, [userRoleId, targetUserId]);

  useEffect(() => {
    if (authUser?.role !== "SuperAdmin" && open) {
      setAuxUserId(authUser?.id || "");
    }
  }, [authUser, open]);

  useEffect(() => {
    if (authUser?.role === "SuperAdmin") {
      setValue("userRoleId", "");
      setValue("targetUserId", "");
      setValue("targetUserRoleId", "");
    }
    if (userId || auxUserId) {
      const reseller = getReseller({
        variables: {
          id: auxUserId ? auxUserId : userId,
        },
      });

      const subReseller = getSubReseller({
        variables: {
          id: auxUserId ? auxUserId : userId,
        },
      });

      Promise.all([reseller, subReseller]).then((res) => {
        if (res[0].data?.getReseller) {
          setUserRole("Reseller");
          return setValue("userRoleId", res[0].data?.getReseller?.id || "");
        }

        if (res[1].data?.getSubReseller) {
          setUserRole("SubReseller");
          return setValue("userRoleId", res[1].data?.getSubReseller?.id || "");
        }

        toast.error("This function is not available for Customers yet");
      });
    }
  }, [userId, auxUserId]);

  function OptionTypeControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    return <StyledRadioButtonsControlLabel checked={checked} {...props} />;
  }

  useEffect(() => {
    reset();
    setUserRole("");
  }, [!open]);

  return (
    <Dialog
      open={open}
      onClose={
        !createCustomerZoneLoading || !createSubResellerZoneLoading
          ? onClose
          : undefined
      }
      maxWidth="sm"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingZone ? "Update Assigned" : "Assign"} Zone to User
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            {authUser?.role === "SuperAdmin" && (
              <Grid item xs={12}>
                <ControlledSelectModalField
                  name="userId"
                  control={control}
                  // variables={
                  //   authUser?.role === "reseller"
                  //     ? { schoolSystemId: authUser.schoolSystemId }
                  //     : undefined
                  // }
                  //   initialValue={
                  //     editingZone?.reseller as ResellersPaginatedItemFragment
                  //   }
                  textFieldProps={{
                    label: "User",
                    fullWidth: true,
                  }}
                  query={GetUsersPaginatedDocument}
                  labelsExractor={(item) => ({
                    primary: item.name || "",
                    secondary: item.role || "",
                  })}
                />
              </Grid>
            )}

            {userRoleId && (
              <Grid item xs={12}>
                <ControlledSelectModalField
                  name="targetUserId"
                  control={control}
                  // variables={
                  //   authUser?.role === "reseller"
                  //     ? { schoolSystemId: authUser.schoolSystemId }
                  //     : undefined
                  // }
                  //   initialValue={
                  //     editingZone?.reseller as ResellersPaginatedItemFragment
                  //   }
                  textFieldProps={{
                    label: "Assign Zone to",
                    fullWidth: true,
                  }}
                  variables={{
                    userRoleId: userRoleId,
                  }}
                  query={
                    userRole === "Reseller"
                      ? GetResellerUsersDocument
                      : GetSubResellerUsersDocument
                  }
                  labelsExractor={(item) => ({
                    primary: item.user.name || "",
                    secondary: item.user.role || "",
                  })}
                />
              </Grid>
            )}
            {targetUserRoleId &&
              (authUser?.role === "Reseller" || userRole === "Reseller") && (
                <Grid item xs={12}>
                  <ControlledSelectModalField
                    name="resellerZoneId"
                    control={control}
                    textFieldProps={{
                      label: "Zones",
                      fullWidth: true,
                    }}
                    variables={{ userId: userId }}
                    query={ResellerZonesPaginatedDocument}
                    labelsExractor={(item) => ({
                      primary: item.zoneName || "",
                    })}
                  />
                </Grid>
              )}
            {targetUserRoleId &&
              (authUser?.role === "SubReseller" ||
                userRole === "SubReseller") && (
                <Grid item xs={12}>
                  <ControlledSelectModalField
                    name="subResellerZoneId"
                    control={control}
                    textFieldProps={{
                      label: "Zones",
                      fullWidth: true,
                    }}
                    variables={{ userId: userId }}
                    query={SubResellerZonesPaginatedDocument}
                    labelsExractor={(item) => ({
                      primary: item.zoneName || "",
                    })}
                  />
                </Grid>
              )}
          </Grid>

          <Grid item xs={12}></Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton
            loading={createSubResellerZoneLoading || createCustomerZoneLoading}
            type="submit"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UsersAssignZoneFormModal;

import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import SignInPage from "../pages/home/SignInPage";
import ResellersListPage from "../pages/resellers/ResellersListPage";
import Main from "./Main";
import PrivateRoute from "./PrivateRoute";

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <SignInPage />
        </Route>

        <PrivateRoute path="/dashboard">
          <Main />
        </PrivateRoute>

        {/* <Route
          path={["/createAccount", "/signup", "/SIGNUP", "/SignUp", "/signUp"]}
          exact
        > */}
        {/* <CreateAccountPage /> */}
        {/* </Route> */}
        {/* <Route path="/app/privacy" exact> */}
        {/* <MobileAppPolicyPage /> */}
        {/* </Route> */}
      </Switch>
    </BrowserRouter>
  );
};

export default AppRoutes;

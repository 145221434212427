import React, { useEffect } from "react";
import {
  Grid,
  Modal,
  Paper,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { LoadingButton } from "@mui/lab";
import toast from "react-hot-toast";

import useDidMountEffect from "../../../shared/hooks/useDidMountEffect";

import ControlledTextField from "../../../shared/components/ControlledTextField";
import ControlledSelectModalField from "../../../shared/components/ControlledSelectModalField";
import apolloClient from "../../../apollo/client";
import {
  CheckEmailAvailabilityQuery,
  CheckEmailAvailabilityDocument,
  CheckEmailAvailabilityQueryVariables,
  useUpdateUserMutation,
} from "../../../apollo/users/queries.generated";

import ControlledSearchSelect from "../../../shared/components/ControlledSearchSelect";
import useAuthUser from "../../../shared/hooks/useAuthUser";
import useAbility from "../../../shared/hooks/useAbility";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
  useCreateResellerMutation,
  useGetResellerByUserIdLazyQuery,
} from "../../../apollo/resellers/queries.generated";
import {
  CreateResellerDto,
  CreateSubResellerDto,
} from "../../../apollo/types.generated";
import {
  GetSubResellersPaginatedDocument,
  SubResellersPaginatedItemFragment,
  useCreateSubResellerMutation,
} from "../../../apollo/subresellers/queries.generated";

interface SubResellersFormModalProps {
  open: boolean;
  onClose: () => void;
  editingSubReseller?: SubResellersPaginatedItemFragment;
}

interface FormInputs extends Omit<CreateSubResellerDto, "password"> {
  passwordConfirmation?: string;
  password?: string;
}

const getSchema = (editingReseller?: SubResellersPaginatedItemFragment) =>
  Yup.object({
    firstName: Yup.string().required("This field is required"),
    lastName: Yup.string().required("This field is required"),
    email: Yup.string()
      .required("This field is required")
      .email("Invalid email")
      .test(
        "test-mail",
        "This email address is not available",
        async (value, context) => {
          if (!value) return false;
          const result = await apolloClient.query<
            CheckEmailAvailabilityQuery,
            CheckEmailAvailabilityQueryVariables
          >({
            query: CheckEmailAvailabilityDocument,
            variables: { email: value, userId: editingReseller?.user.id },
          });

          return result.data.checkEmailAvailability;
        }
      ),
    password: editingReseller
      ? Yup.string().optional()
      : Yup.string().required("This field is required"),
    passwordConfirmation: editingReseller
      ? Yup.string()
          .optional()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required(""),
    resellerId: Yup.string().required("This field is required"),
  }).required();

const getDefaultValues = (
  subReseller?: SubResellersPaginatedItemFragment
): FormInputs | undefined => {
  return {
    firstName: subReseller?.user.firstName || "",
    lastName: subReseller?.user.lastName || "",
    email: subReseller?.user.email || "",
    password: "",
    passwordConfirmation: "",
    resellerId: subReseller?.resellerId || "",
  };
};
const SubResellerFormModal: React.FC<SubResellersFormModalProps> = ({
  open,
  onClose,
  editingSubReseller,
}) => {
  const authUser = useAuthUser();
  const ability = useAbility();
  const { control, handleSubmit, reset, setValue, unregister } =
    useForm<FormInputs>({
      resolver: yupResolver(getSchema(editingSubReseller)),
      shouldFocusError: true,
      defaultValues: getDefaultValues(editingSubReseller),
    });

  const [
    create,
    { data: createData, loading: createLoading, error: createError },
  ] = useCreateSubResellerMutation({
    refetchQueries: [GetSubResellersPaginatedDocument],
  });

  const [getReseller, { data: resellerData }] =
    useGetResellerByUserIdLazyQuery();

  const [
    update,
    { data: updateData, loading: updateLoading, error: updateError },
  ] = useUpdateUserMutation({
    refetchQueries: [GetSubResellersPaginatedDocument],
  });

  const onSubmit = (data: FormInputs) => {
    const { passwordConfirmation, ...rest } = data;
    const dataToSubmit = { ...rest };
    if (editingSubReseller) {
      const { resellerId, ...userData } = dataToSubmit;

      update({
        variables: {
          id: editingSubReseller?.user?.id,
          data: userData,
        },
      });
      return;
    }

    if (rest.password) {
      create({
        variables: {
          data: {
            ...dataToSubmit,
            password: rest.password,
          },
        },
      });
    }
  };

  useEffect(() => {
    if (createData) {
      toast.success("Sub Reseller created successfully");
      reset();
      onClose();
    }

    if (createError) {
      toast.error("Error creating Sub Reseller: " + createError.message);
    }
  }, [createData, createError]);

  useEffect(() => {
    if (updateData) {
      toast.success("SubReseller updated successfully");
      onClose();
    }

    if (updateError) {
      toast.error("Error updating SubReseller: " + updateError.message);
    }
  }, [updateData, updateError]);

  useDidMountEffect(() => {
    reset(getDefaultValues(editingSubReseller));
  }, [editingSubReseller]);

  useEffect(() => {
    if (authUser?.role === "Reseller") {
      getReseller({
        variables: {
          id: authUser?.id,
        },
      });
    }
  }, [authUser]);

  useEffect(() => {
    if (resellerData) {
      setValue("resellerId", resellerData.getReseller.id);
    }
  }, [resellerData]);

  return (
    <Dialog
      open={open}
      onClose={!createLoading ? onClose : undefined}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <DialogTitle>
          {editingSubReseller ? "Update" : "Create"} Sub Reseller
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ marginTop: "10px" }} spacing={2}>
            <Grid item xs={12}>
              <ControlledTextField
                name="firstName"
                control={control}
                label="First Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="lastName"
                control={control}
                label="Last Name"
              />
            </Grid>
            <Grid item xs={12}>
              <ControlledTextField
                name="email"
                control={control}
                label="Email"
              />
            </Grid>

            <Grid item xs={12}>
              {!!editingSubReseller && (
                <Typography variant="caption" color="GrayText">
                  You can skip this field to omit changing the Sub Reseller's
                  password
                </Typography>
              )}
              <ControlledTextField
                name="password"
                control={control}
                type="password"
                label="Password"
              />
            </Grid>

            <Grid item xs={12}>
              <ControlledTextField
                type="password"
                name="passwordConfirmation"
                control={control}
                label="Password Confirmation"
              />
            </Grid>
            {authUser?.role === "SuperAdmin" && !editingSubReseller && (
              <Grid item xs={12}>
                <ControlledSelectModalField
                  name="resellerId"
                  control={control}
                  // variables={
                  //   authUser?.role === "reseller"
                  //     ? { schoolSystemId: authUser.schoolSystemId }
                  //     : undefined
                  // }
                  // initialValue={
                  //   editingSubReseller?.reseller as ResellersPaginatedItemFragment
                  // }
                  textFieldProps={{
                    label: "Reseller",
                    fullWidth: true,
                  }}
                  query={GetResellersPaginatedDocument}
                  labelsExractor={(item) => ({
                    primary: item.user.name,
                  })}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel </Button>
          <LoadingButton loading={createLoading || updateLoading} type="submit">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default SubResellerFormModal;

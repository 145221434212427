import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";
import {
  CustomerFragment,
  CustomersPaginatedDocument,
} from "../../../apollo/customers/queries.generated";
import useAuthUser from "../../../shared/hooks/useAuthUser";

interface CustomersTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<CustomerFragment>;
  onDeletePress?: BasicTableActionFunction<CustomerFragment>;
}
const muiColumns: GridColDef[] = [
  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const customer = row as CustomerFragment;
      return (
        <Link
          to={"/dashboard/reseller/" + customer.id}
        >{`${customer.user.name}`}</Link>
      );
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const customer = row as CustomerFragment;
      return customer.user.email;
    },
  },
  {
    flex: 1,
    field: "zonesCount",
    headerName: "Total Zones",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const customer = row as CustomerFragment;
      return customer.zones?.length;
    },
  },
];

const CustomersTable: React.FC<CustomersTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  const authUser = useAuthUser();

  if (authUser?.role === "SuperAdmin") {
    const resellerColumn = muiColumns.find(
      (column) => column.field === "reseller"
    );

    if (!resellerColumn) {
      muiColumns.push({
        flex: 1,
        field: "reseller",
        headerName: "Reseller",
        minWidth: 200,
        valueGetter: ({ row }) => {
          const customer = row as CustomerFragment;
          return customer?.reseller?.user?.name || "-";
        },
      });
    }

    const subResellerColumn = muiColumns.find(
      (column) => column.field === "subReseller"
    );

    if (!subResellerColumn) {
      muiColumns.push({
        flex: 1,
        field: "subReseller",
        headerName: "Sub Reseller",
        minWidth: 200,
        valueGetter: ({ row }) => {
          const customer = row as CustomerFragment;
          return customer?.subReseller?.user?.name || "-";
        },
      });
    }
  }

  return (
    <RemoteDataTable
      query={CustomersPaginatedDocument}
      searchable
      variables={
        authUser?.role === "SuperAdmin" ? {} : { userId: authUser?.id }
      }
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default CustomersTable;

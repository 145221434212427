import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Radio,
  RadioGroup,
  styled,
  Typography,
  useRadioGroup,
} from "@mui/material";
import React, { useEffect } from "react";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import CustomRolesPermissionPage from "./components/CustomRolesPermissionPage";
import UserPermissionPage from "./components/UserPermissionPage";

interface PermissionsPageProps {}

interface StyledFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
}

const StyledRadioButtonsControlLabel = styled(
  (props: StyledFormControlLabelProps) => <FormControlLabel {...props} />
)(({ theme, checked }) => ({
  ".MuiFormControlLabel-label": checked && {
    color: theme.palette.primary.main,
  },
}));

const PermissionsPage: React.FC<PermissionsPageProps> = ({}) => {
  const [option, setOption] = React.useState<String>("user");

  function ContractorTypeControlLabel(props: FormControlLabelProps) {
    const radioGroup = useRadioGroup();

    let checked = false;

    if (radioGroup) {
      checked = radioGroup.value === props.value;
    }

    useEffect(() => {
      if (radioGroup) {
        setOption(radioGroup.value);
      }
    }, [radioGroup?.value]);

    return <StyledRadioButtonsControlLabel checked={checked} {...props} />;
  }

  return (
    <PageContainer>
      <TableCard>
        <Typography
          variant="h5"
          textAlign="start"
          fontWeight="bold"
          color="secondary"
          gutterBottom
        >
          Permissions
        </Typography>

        <Grid item xs={12} margin={"10px"}>
          <Typography variant="inherit" textAlign="start">
            Select the type of Permission
          </Typography>
          <RadioGroup row name="contractor" defaultValue="user">
            <ContractorTypeControlLabel
              value="user"
              label="Users"
              control={<Radio />}
            />
            <ContractorTypeControlLabel
              value="customRole"
              label="Custom Roles"
              control={<Radio />}
            />
          </RadioGroup>
        </Grid>

        {option === "user" ? (
          <UserPermissionPage />
        ) : (
          <CustomRolesPermissionPage />
        )}
      </TableCard>
    </PageContainer>
  );
};

export default PermissionsPage;

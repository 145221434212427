import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";
import {
  CustomRoleFragment,
  GetUserCustomRolesPaginatedDocument,
} from "../../../apollo/users/queries.generated";
import useAuthStore from "../../../shared/stores/authStore";
import { PermissionsDto } from "../../../apollo/types.generated";

interface MyRolesTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<CustomRoleFragment>;
  onDeletePress?: BasicTableActionFunction<CustomRoleFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },

  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const role = row as CustomRoleFragment;
      return <Link to={"/dashboard/roles/" + role.id}>{`${role.name}`}</Link>;
    },
  },
  {
    flex: 1,
    field: "desc",
    headerName: "Description",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const role = row as CustomRoleFragment;
      return role.desc;
    },
  },
  {
    flex: 1,
    field: "permissionsCount",
    headerName: "Permissions",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const role = row as CustomRoleFragment;
      return role.permissions!.length;
    },
  },
];

const MyRolesTable: React.FC<MyRolesTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  const { user } = useAuthStore(({ user }) => ({
    user,
  }));

  if (user?.role === "SuperAdmin") {
    const createdBy = muiColumns.find((column) => column.field === "user");

    if (!createdBy) {
      muiColumns.push({
        flex: 1,
        field: "user",
        headerName: "Created By",
        minWidth: 200,
        valueGetter: ({ row }) => {
          const role = row as CustomRoleFragment;
          return role.user!.name;
        },
      });
    }
  }

  return (
    <RemoteDataTable
      query={GetUserCustomRolesPaginatedDocument}
      variables={user?.role === "SuperAdmin" ? {} : { userId: user?.id }}
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default MyRolesTable;

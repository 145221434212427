import { Grid, Typography } from "@mui/material";
import React from "react";
import ZonesPage from "../../zones/ZonesPage";
interface AdminContentProps {}

const AdminContent: React.FC<AdminContentProps> = () => {
  return (
    // <Grid container>
    //   <Grid item xs={12}>
    //     <Typography variant="h3" textAlign={"start"}>
    //       Admin Content
    //     </Typography>

    <ZonesPage />
    //   </Grid>
    // </Grid>
  );
};

export default AdminContent;

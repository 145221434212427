// import { Grid, Typography } from "@mui/material";
// import { Box } from "@mui/system";
// import useAbility from "../../shared/hooks/useAbility";
// // import { useGetZoneDnsRecordsQuery } from "../../apollo/dns/queries.generated";
// import ZonesTable from "./components/ZonesTable";

// interface ZonesPageProps {}

// const ZonesPage: React.FC<ZonesPageProps> = ({}) => {
//   const ability = useAbility();

//   const renderZonesTable = ability.can("manage", "Zones");

//   return (
//     <Box
//       sx={{
//         display: "flex",
//         justifyContent: "center",
//         height: "100vh",
//       }}
//     >
//       <Grid container spacing={2}>
//         {/* <Typography variant="h4">Zones Page</Typography> */}
//         <Grid item xs={11}>
//           <Typography variant="h2">Zone List</Typography>
//           {renderZonesTable && <ZonesTable />}
//         </Grid>

//         {/* {ability.can("read", "Zones") ?? (
//             <Grid item xs={11}>
//               <Typography variant="h2">Zone List</Typography>
//               <ZonesTable />
//             </Grid>
//           )} */}
//       </Grid>
//     </Box>
//   );
// };

// export default ZonesPage;

import {
  Box,
  Button,
  Card,
  FormControlLabel,
  FormControlLabelProps,
  Grid,
  Radio,
  RadioGroup,
  styled,
  TextField,
  Typography,
  useRadioGroup,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
} from "../../apollo/resellers/queries.generated";
import { SubResellersPaginatedItemFragment } from "../../apollo/subresellers/queries.generated";
import { ResellerZoneDto } from "../../apollo/types.generated";
import {
  GetZoneByNameDocument,
  ZoneFragment,
} from "../../apollo/zones/queries.generated";
import DeleteModal from "../../shared/components/DeleteModal";
import PageContainer from "../../shared/components/PageContainer";
import TableCard from "../../shared/components/TableCard";
import ResellerAssignZoneFormModal from "./components/ResellerAssignZoneFormModal";
import ManageZonesPage from "./ManageZones";
import ZonesTable from "./components/ZonesTable";
import { useQuery } from "@apollo/client";
import { NavLink } from "react-router-dom";
interface ZonePageProps {}

const ZonePage: React.FC<ZonePageProps> = ({}) => {
  const [showFormModal, setShowFormModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedZone, setSelectedZone] = useState<ResellerZoneDto>();
  const [zoneField, setZoneField] = React.useState("");
  const { data: getZoneData, loading: getZoneLoading } = useQuery(
    GetZoneByNameDocument,
    {
      variables: {
        zoneName: zoneField,
      },
    }
  );

  const handleFormClose = () => {
    setSelectedZone(undefined);
    setShowFormModal(false);
  };

  const handleDeleteModalClose = () => {
    setSelectedZone(undefined);
    setShowDeleteModal(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            textAlign="start"
            fontWeight="bold"
            color="secondary"
            gutterBottom
          >
            Zones
          </Typography>
          <Grid container alignItems={"center"} justifyContent={"flex-end"}>
            <Grid item xs={4}>
              {/* <ControlledTextField
                    name="zoneId"
                    control={control}
                    label="Zone Name"
                  /> */}
              <TextField
                label="Search Zone"
                fullWidth
                value={zoneField}
                onChange={(e) => {
                  setZoneField(e.target.value);
                }}
                error={!!zoneField && !getZoneData?.getZoneByName?.name}
                helperText={
                  !!zoneField && !getZoneData?.getZoneByName?.name
                    ? "Zone does not exist"
                    : ""
                }
              />
            </Grid>
            <Grid
              item
              // xs={2}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                marginLeft: "5px",
              }}
            >
              {zoneField ? (
                getZoneData?.getZoneByName?.name ? (
                  <Button
                    variant="contained"
                    color="primary"
                    component={NavLink}
                    to={`/dashboard/zones/${zoneField}`}
                  >
                    Search
                  </Button>
                ) : (
                  <Button variant="contained" disabled>
                    Search
                  </Button>
                )
              ) : (
                <Button variant="contained" disabled>
                  Search
                </Button>
              )}
            </Grid>
          </Grid>

          <ZonesTable />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ZonePage;

import { GridColDef } from "@mui/x-data-grid";
import React from "react";

import RemoteDataTable, {
  BasicTableActionFunction,
  getBasicActionColumns,
  RemoteDataTableProps,
} from "../../../shared/components/RemoteDataTable";
import Link from "../../../navigation/Link";
import {
  GetResellersPaginatedDocument,
  ResellersPaginatedItemFragment,
} from "../../../apollo/resellers/queries.generated";
import {
  UserFragment,
  UsersPaginatedItemFragment,
} from "../../../apollo/users/queries.generated";

interface ResellersTableProps
  extends Pick<RemoteDataTableProps, "onAddClick" | "refresherBoolean"> {
  onEditPress?: BasicTableActionFunction<ResellersPaginatedItemFragment>;
  onDeletePress?: BasicTableActionFunction<ResellersPaginatedItemFragment>;
}
const muiColumns: GridColDef[] = [
  // {
  //   flex: 1,
  //   minWidth: 200,
  //   field: "id",
  //   sortable: false,
  //   headerName: "View",
  //   renderCell: (params) => {
  //     return <Button>{params.value}</Button>;
  //   },
  // },

  {
    flex: 1,
    field: "name",
    headerName: "Name",
    minWidth: 200,
    renderCell: ({ row }) => {
      const reseller = row as ResellersPaginatedItemFragment;
      return (
        <Link
          to={"/dashboard/reseller/" + reseller.id}
        >{`${reseller.user.name}`}</Link>
      );
    },
  },
  {
    flex: 1,
    field: "email",
    headerName: "Email",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const reseller = row as ResellersPaginatedItemFragment;
      return reseller.user.email;
    },
  },
  {
    flex: 1,
    field: "zonesCount",
    headerName: "Total Zones",
    minWidth: 200,
    valueGetter: ({ row }) => {
      const reseller = row as ResellersPaginatedItemFragment;
      return reseller.zones?.length;
    },
  },
];

const ResellersTable: React.FC<ResellersTableProps> = ({
  onEditPress,
  onDeletePress,
  ...props
}) => {
  return (
    <RemoteDataTable
      query={GetResellersPaginatedDocument}
      searchable
      renderAddButton
      columns={muiColumns}
      actionColumns={getBasicActionColumns(onEditPress, onDeletePress)}
      {...props}
    />
  );
};

export default ResellersTable;

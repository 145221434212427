import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DnsRecordFragment = { id: string, zone_id: string, zone_name: string, name: string, type: string, content: string, created_on: string, modified_on: string, ttl: number, comment?: string | null, priority?: number | null, proxiable: boolean, proxied: boolean };

export type GetZoneDnsRecordsQueryVariables = Types.Exact<{
  zoneName: Types.Scalars['String'];
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  page?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type GetZoneDnsRecordsQuery = { getZoneDnsRecords: { totalsCount: number, nodes?: Array<{ id: string, zone_id: string, zone_name: string, name: string, type: string, content: string, created_on: string, modified_on: string, ttl: number, comment?: string | null, priority?: number | null, proxiable: boolean, proxied: boolean }> | null } };

export type GetDnsRecordQueryVariables = Types.Exact<{
  zoneName: Types.Scalars['String'];
  recordId: Types.Scalars['String'];
}>;


export type GetDnsRecordQuery = { getDnsRecord: { id: string, zone_id: string, zone_name: string, name: string, type: string, content: string, created_on: string, modified_on: string, ttl: number, comment?: string | null, priority?: number | null, proxiable: boolean, proxied: boolean } };

export type CreateDnsRecordMutationVariables = Types.Exact<{
  data: Types.CreateUpdateDnsdto;
}>;


export type CreateDnsRecordMutation = { createDnsRecord: boolean };

export type UpdateDnsRecordMutationVariables = Types.Exact<{
  data: Types.CreateUpdateDnsdto;
}>;


export type UpdateDnsRecordMutation = { updateDnsRecord: boolean };

export type DeleteDnsRecordMutationVariables = Types.Exact<{
  zoneId: Types.Scalars['String'];
  recordId: Types.Scalars['String'];
}>;


export type DeleteDnsRecordMutation = { deleteDnsRecord: boolean };

export const DnsRecordFragmentDoc = gql`
    fragment DnsRecord on DNSRecordDTO {
  id
  zone_id
  zone_name
  name
  type
  content
  created_on
  modified_on
  ttl
  comment
  priority
  proxiable
  proxied
}
    `;
export const GetZoneDnsRecordsDocument = gql`
    query getZoneDnsRecords($zoneName: String!, $limit: Int, $page: Int) {
  getZoneDnsRecords(zoneName: $zoneName, limit: $limit, page: $page) {
    nodes {
      ...DnsRecord
    }
    totalsCount
  }
}
    ${DnsRecordFragmentDoc}`;

/**
 * __useGetZoneDnsRecordsQuery__
 *
 * To run a query within a React component, call `useGetZoneDnsRecordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetZoneDnsRecordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetZoneDnsRecordsQuery({
 *   variables: {
 *      zoneName: // value for 'zoneName'
 *      limit: // value for 'limit'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetZoneDnsRecordsQuery(baseOptions: Apollo.QueryHookOptions<GetZoneDnsRecordsQuery, GetZoneDnsRecordsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetZoneDnsRecordsQuery, GetZoneDnsRecordsQueryVariables>(GetZoneDnsRecordsDocument, options);
      }
export function useGetZoneDnsRecordsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetZoneDnsRecordsQuery, GetZoneDnsRecordsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetZoneDnsRecordsQuery, GetZoneDnsRecordsQueryVariables>(GetZoneDnsRecordsDocument, options);
        }
export type GetZoneDnsRecordsQueryHookResult = ReturnType<typeof useGetZoneDnsRecordsQuery>;
export type GetZoneDnsRecordsLazyQueryHookResult = ReturnType<typeof useGetZoneDnsRecordsLazyQuery>;
export type GetZoneDnsRecordsQueryResult = Apollo.QueryResult<GetZoneDnsRecordsQuery, GetZoneDnsRecordsQueryVariables>;
export const GetDnsRecordDocument = gql`
    query getDnsRecord($zoneName: String!, $recordId: String!) {
  getDnsRecord(zoneName: $zoneName, recordId: $recordId) {
    ...DnsRecord
  }
}
    ${DnsRecordFragmentDoc}`;

/**
 * __useGetDnsRecordQuery__
 *
 * To run a query within a React component, call `useGetDnsRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDnsRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDnsRecordQuery({
 *   variables: {
 *      zoneName: // value for 'zoneName'
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useGetDnsRecordQuery(baseOptions: Apollo.QueryHookOptions<GetDnsRecordQuery, GetDnsRecordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDnsRecordQuery, GetDnsRecordQueryVariables>(GetDnsRecordDocument, options);
      }
export function useGetDnsRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDnsRecordQuery, GetDnsRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDnsRecordQuery, GetDnsRecordQueryVariables>(GetDnsRecordDocument, options);
        }
export type GetDnsRecordQueryHookResult = ReturnType<typeof useGetDnsRecordQuery>;
export type GetDnsRecordLazyQueryHookResult = ReturnType<typeof useGetDnsRecordLazyQuery>;
export type GetDnsRecordQueryResult = Apollo.QueryResult<GetDnsRecordQuery, GetDnsRecordQueryVariables>;
export const CreateDnsRecordDocument = gql`
    mutation createDnsRecord($data: CreateUpdateDNSDTO!) {
  createDnsRecord(data: $data)
}
    `;
export type CreateDnsRecordMutationFn = Apollo.MutationFunction<CreateDnsRecordMutation, CreateDnsRecordMutationVariables>;

/**
 * __useCreateDnsRecordMutation__
 *
 * To run a mutation, you first call `useCreateDnsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDnsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDnsRecordMutation, { data, loading, error }] = useCreateDnsRecordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateDnsRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateDnsRecordMutation, CreateDnsRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDnsRecordMutation, CreateDnsRecordMutationVariables>(CreateDnsRecordDocument, options);
      }
export type CreateDnsRecordMutationHookResult = ReturnType<typeof useCreateDnsRecordMutation>;
export type CreateDnsRecordMutationResult = Apollo.MutationResult<CreateDnsRecordMutation>;
export type CreateDnsRecordMutationOptions = Apollo.BaseMutationOptions<CreateDnsRecordMutation, CreateDnsRecordMutationVariables>;
export const UpdateDnsRecordDocument = gql`
    mutation updateDnsRecord($data: CreateUpdateDNSDTO!) {
  updateDnsRecord(data: $data)
}
    `;
export type UpdateDnsRecordMutationFn = Apollo.MutationFunction<UpdateDnsRecordMutation, UpdateDnsRecordMutationVariables>;

/**
 * __useUpdateDnsRecordMutation__
 *
 * To run a mutation, you first call `useUpdateDnsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDnsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDnsRecordMutation, { data, loading, error }] = useUpdateDnsRecordMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateDnsRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDnsRecordMutation, UpdateDnsRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDnsRecordMutation, UpdateDnsRecordMutationVariables>(UpdateDnsRecordDocument, options);
      }
export type UpdateDnsRecordMutationHookResult = ReturnType<typeof useUpdateDnsRecordMutation>;
export type UpdateDnsRecordMutationResult = Apollo.MutationResult<UpdateDnsRecordMutation>;
export type UpdateDnsRecordMutationOptions = Apollo.BaseMutationOptions<UpdateDnsRecordMutation, UpdateDnsRecordMutationVariables>;
export const DeleteDnsRecordDocument = gql`
    mutation deleteDnsRecord($zoneId: String!, $recordId: String!) {
  deleteDnsRecord(zoneId: $zoneId, recordId: $recordId)
}
    `;
export type DeleteDnsRecordMutationFn = Apollo.MutationFunction<DeleteDnsRecordMutation, DeleteDnsRecordMutationVariables>;

/**
 * __useDeleteDnsRecordMutation__
 *
 * To run a mutation, you first call `useDeleteDnsRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDnsRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDnsRecordMutation, { data, loading, error }] = useDeleteDnsRecordMutation({
 *   variables: {
 *      zoneId: // value for 'zoneId'
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useDeleteDnsRecordMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDnsRecordMutation, DeleteDnsRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDnsRecordMutation, DeleteDnsRecordMutationVariables>(DeleteDnsRecordDocument, options);
      }
export type DeleteDnsRecordMutationHookResult = ReturnType<typeof useDeleteDnsRecordMutation>;
export type DeleteDnsRecordMutationResult = Apollo.MutationResult<DeleteDnsRecordMutation>;
export type DeleteDnsRecordMutationOptions = Apollo.BaseMutationOptions<DeleteDnsRecordMutation, DeleteDnsRecordMutationVariables>;